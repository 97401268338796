import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html'
})
export class FeaturesComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'Toutes les fonctionnalités que votre site pourrait demander',
    subTitle: 'Features',
    titleIcon: '',
    description:'Que vous soyez blogueur, propriétaire de petite entreprise, concepteur Web ou développeur, Zenhosting vous permet de démarrer facilement votre voyage en ligne.    '
  };

  List: any[] = [
    { 
      title: 'Panel facile à utiliser',
      image: 'fas fa-tv',
      link : '/services',
      desc : 'Obtenez un accès administrateur à tout ce dont vous avez besoin pour alimenter votre site Web à plein régime. Créez facilement de nouveaux comptes de messagerie , transférez des domaines, ajoutez des utilisateurs et installez WordPress en quelques secondes.     '
    },
    { 
      title: 'Stockage SSD NVME',
      image: 'fad fa-server',
      link : '/services',
      desc : 'Ne faites pas attendre vos visiteurs pendant de longs temps de chargement. Avec les disques SSD, votre site Web, la mise en cache et les requêtes de base de données sont plus rapides - nous parlons 200% plus vite - que les disques durs de la vieille école HDD.'
    },
    { 
      title: 'Protection des ressources',
      image: 'fad fa-clouds',
      link : '/services',
      desc : 'Nous sommes là pour vous. Parce que nous sommes très attachés à la protection de vos biens, nous gardons vaillamment un œil sur les choses pour vous afin que vous puissiez recommencer à faire ce que vous faites de mieux.'
    },
    { 
      title: 'Serveurs optimisés',
      image: 'fad fa-retweet',
      link : '/services',
      desc : "Nous avons compris. Vous avez besoin d'un site Web qui fonctionne à pleine capacité. Nous avons conçu tous nos services en gardant à l'esprit les performances optimales. Toute notre technologie est conçue pour exécuter votre site web comme un rêve"
    },
    { 
      title: 'Certificat SSL GRATUITE',
      image: 'fab fa-expeditedssl',
      link : '/services',
      desc : 'Nous fournissons un certificat SSL gratuit «Let’s Encrypt» pour protéger votre site avec HTTPS, en chiffrant le trafic et en stimulant instantanément le référencement avec Google.'
    },
    { 
      title: 'Sauvegardes automatisées',
      image: 'fad fa-reply-all',
      link : '/services',
      desc : 'La chose la plus importante que vous puissiez faire pour assurer la sécurité de votre site Web? Sauvegardez-le. Zenhosting rend cela facile - nous créons chaque jour des sauvegardes automatiques de votre site Web.'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
