<section class="wow fadeInUp iq-application" style="background: url(assets/images/others/07.jpg) no-repeat 0 0; background-attachment: fixed; background-size:cover; ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round iq-btn-outline d-inline" href="#">View Benchmarks </a>
                </div>
            </div>
        </div>
    </div>
</section>
   