import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-client',
  templateUrl: './our-client.component.html',
  styleUrls: ['./style.scss']
})
export class OurClientComponent implements OnInit {
  titleSectionProp: any = {
    class: 'iq-title-box-2',
    title: 'NOS CLIENTS SONT FORMIDABLES',
    subTitle: 'Témoignage',
    titleIcon: '',
    description: 'Nous avons des clients formidables qui adorent nos produits et nous voulons faire connaître et partager leur enthousiasme.'
  };
  Images: any[] = [
    { image: './assets/images/client/01.png'},
    { image: './assets/images/client/02.png'},
    { image: './assets/images/client/03.png'},
    { image: './assets/images/client/04.png'},
    { image: './assets/images/client/05.png'},
    { image: './assets/images/client/06.png'}
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
