<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <img src="assets/images/others/02.png" class="img-fluid" alt="qloud">
            </div>
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <ul class="job-details d-inline-block p-0">
                    <li class="list-unstyled">
                        <h5 class="deatils d-inline-block">Client :</h5>
                        <span class="pl-3">Qloud</span>
                    </li>
                    <li class="list-unstyled">
                        <h5 class="deatils d-inline-block">Website :</h5>
                        <span class="pl-3">Qlouddummy.com</span>
                    </li>
                </ul>
                <ul class="job-details d-inline-block ml-lg-5 p-0">
                    <li class="list-unstyled">
                        <h5 class="deatils d-inline-block">Date :</h5>
                        <span class="pl-3">01 january 2019</span>
                    </li>
                    <li class="list-unstyled">
                        <h5 class="deatils d-inline-block">Category :</h5>
                        <span class="pl-3">Business</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>