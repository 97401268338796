<section class="iq-contact-h3 blue-bg">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 wow fadeInUp">                
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>

                <h5 class="text-white mb-2">Phone :</h5>
                <p class="text-white">0 123 456 789</p>
                <h5 class="text-white mb-2">Email:</h5>
                <p class="text-white">mail@dummytheme.com</p>
            </div>
            <div class="col-lg-6 col-md-12 wow fadeInUp">

                <form class="wpcf7-form" novalidate="novalidate">
                    <div style="display: none;">
                        <input type="hidden" name="_wpcf7" value="507" />
                        <input type="hidden" name="_wpcf7_version" value="5.1.6" />
                        <input type="hidden" name="_wpcf7_locale" value="en_US" />
                        <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f507-p385-o1" />
                        <input type="hidden" name="_wpcf7_container_post" value="385" />
                    </div>
                    <p><span class="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="Your Name" /></span>
                        <br />
                        <span class="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="Your Email" /></span>
                        <br />
                        <span class="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="Type Your Message"></textarea></span>
                        <br />
                        <input type="submit" value="Send" class="wpcf7-form-control wpcf7-submit" />
                    </p>
                    <div class="wpcf7-response-output wpcf7-display-none"></div>
                </form>
            </div>
        </div>
    </div>
</section>