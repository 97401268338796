<section class="iq-pricing-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="iq-accordion  iq-accordion-semi-round iq-accordion-classic">
                    <div *ngFor="let list of List;let i = index" class="iq-accordion-block {{ i }} {{ list.active !== undefined && list.active ? 'accordion-active' : '' }}">
                        <div class="iq-accordion-title">
                            <div class="iq-icon-right"><i aria-hidden="true" class="ion ion-minus-round active"></i><i aria-hidden="true" class="ion ion-plus inactive"></i></div>
                            <h5 class="mb-0 accordion-title">{{ list.question }}</h5>
                        </div>
                        <div class="iq-accordion-details">
                            <p class="iq-content-text">{{ list.answer }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>