<section class="icon-box-top pt-0 pb-0">
    <div class="container">
        <div class="row">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 col-sm-12 {{  i < List.length - 1  ? 'mb-lg-0 mb-5' : '' }}">
                <div class="iq-icon-box iq-icon-box-style-2 active wow fadeInUp">
                    <div class="icon-box-img">
                        <img src="{{ list.images }}" class="img-fluid" alt="qloud">
                    </div>
                    <div class="icon-box-content">
                        <h5 class="icon-box-title"> <a href="#">{{ list.title }}</a></h5>
                        <p class="icon-box-desc"> {{ list.desc }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>