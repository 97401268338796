import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview-block',
  templateUrl: './overview-block.component.html'
})
export class OverviewBlockComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
}
