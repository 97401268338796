<section class="pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 wow fadeInUp">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div >
            <div style="margin: auto;">
                <ul class="nav nav-pills" id="pills-tab" role="tablist" style="margin-left: 25%;">
                    <li *ngFor="let list of List2;let i=index" class="nav-item" style="width: 20%;" (click)="service(i)">
                        <a class="nav-link {{ list.active !== undefined && list.active ? 'active' : 'test' }}" data-toggle="pill" href="{{ '#' + list.id}}" role="tab">
                            <div class="media">
                                <div class="media-body" style="text-align: center;color: white;">
                                    <h6 class="tab-title"><i aria-hidden="true" class="{{ list.icon }}"></i>{{ list.title }}</h6>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <br>
        <div class="row iq-compute-list wow fadeInUp animate__animated animate__bounceIn" *ngIf="ind==0">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 {{  i < List.length - 1  ? 'border-right mb-lg-0 mb-4' : '' }} {{  i != 0 ? 'pl-lg-5' : '' }}" style="text-align: center;">
                <h3 class="heading-title mb-4 ">{{ list.title }}</h3>
                <p *ngIf="list.description" style="margin-bottom: -5px;">{{ list.description }}</p>

                <h4><span style="font-size: 15px;">Dt</span>6.99<br>
                    <span style="font-size: 15px;">Par mois</span></h4>
              
                <div class="iq-btn-container" style="margin-top: 10px;">
                    <a class="iq-button iq-btn-round d-inline" style="color: white;">Commander &nbsp;<i
                            class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i></a>
                </div>
                <br>
                <span>Or <span style="color:rgb(0, 119, 255)">Purchase now</span></span>
            </div>
        </div>
        <div class="row iq-compute-list wow fadeInUp animate__animated animate__bounceIn" *ngIf="ind==1">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 {{  i < List.length - 1  ? 'border-right mb-lg-0 mb-4' : '' }} {{  i != 0 ? 'pl-lg-5' : '' }}" style="text-align: center;">
                <h3 class="heading-title mb-4 ">{{ list.title }}</h3>
                <p *ngIf="list.description" style="margin-bottom: -5px;">{{ list.description }}</p>

                <h4><span style="font-size: 15px;">Dt</span>6.99<br>
                    <span style="font-size: 15px;">Par mois</span></h4>
              
                <div class="iq-btn-container" style="margin-top: 10px;">
                    <a class="iq-button iq-btn-round d-inline" style="color: white;">Commander &nbsp;<i
                            class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i></a>
                </div>
                <br>
                <span>Or <span style="color:rgb(0, 119, 255)">Purchase now</span></span>
            </div>
        </div>
        <div class="row iq-compute-list wow fadeInUp animate__animated animate__bounceIn" *ngIf="ind==2">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 {{  i < List.length - 1  ? 'border-right mb-lg-0 mb-4' : '' }} {{  i != 0 ? 'pl-lg-5' : '' }}" style="text-align: center;">
                <h3 class="heading-title mb-4 ">{{ list.title }}</h3>
                <p *ngIf="list.description" style="margin-bottom: -5px;">{{ list.description }}</p>

                <h4><span style="font-size: 15px;">Dt</span>6.99<br>
                    <span style="font-size: 15px;">Par mois</span></h4>
              
                <div class="iq-btn-container" style="margin-top: 10px;">
                    <a class="iq-button iq-btn-round d-inline" style="color: white;">Commander &nbsp;<i
                            class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i></a>
                </div>
                <br>
                <span>Or <span style="color:rgb(0, 119, 255)">Purchase now</span></span>
            </div>
        </div>

        </div>
    </div>
</section>
