<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Breadcrumb Start -->
<app-top-banner [data]="data"></app-top-banner>
<!-- Breadcrumb End -->
<!-- Blog Start -->
    <div class="iq-blog-section overview-block-ptb">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <div class="iq-blog-box">
                        <div class="iq-blog-image clearfix">
                            <img src="assets/images/blog/01.png" class="img-fluid" alt="qloud" />
                            <ul class="iq-blogtag">
                                <li><a href="javascript:void(0)">Marketing</a></li>
                            </ul>
                        </div>
                        <div class="iq-blog-detail">
                            <div class="iq-blog-meta">
                                <ul class="iq-postdate">
                                    <li class="list-inline-item">
                                        <i class="fa fa-calendar mr-2"></i>
                                        <span class="screen-reader-text">Posted on</span> 
                                        <a href="javascript:void(0)"  rel="bookmark">
                                            <time class="entry-date published updated" datetime="2020-02-17T06:44:40+00:00">February 17, 2020</time>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="blog-content">
                                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using &#8216;Content here, content here&#8217;, making it look like readable English.</p>
                                <blockquote class="wp-block-quote">
                                    <p>Many desktop publishing packages and web page editors now use Lorem Ipsum as
                                        their default model text, and a search for &#8216;lorem ipsum&#8217; will
                                        uncover many web sites still in their infancy. – Someone famous in Source Title
                                    </p>
                                </blockquote>
                                <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
                                    letters, as opposed to using &#8216;Content here, content here&#8217;, making it
                                    look like readable English. Many desktop publishing packages and web page editors
                                    now use Lorem Ipsum as their default model text, and a search for &#8216;lorem
                                    ipsum&#8217; will uncover many web sites still in their infancy.</p>
                            </div>
                        </div>
                    </div>
                    <div id="comments" class="comments-area">
                        <div id="respond" class="comment-respond">
                            <h3 id="reply-title" class="comment-reply-title">Leave a Reply 
                                <small> <a rel="nofollow" id="cancel-comment-reply-link" style="display:none;">Cancel reply</a></small>
                            </h3>
                            <form id="commentform" class="comment-form" novalidate>
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked <span class="required">*</span>
                                </p>
                                <p class="comment-form-comment">
                                    <label for="comment">Comment</label>
                                    <textarea id="comment" name="comment" cols="45" rows="8" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-author">
                                    <label for="author">Name <span class="required">*</span></label>
                                    <input id="author" name="author" type="text" value="" size="30" maxlength="245" required='required' />
                                </p>
                                <p class="comment-form-email">
                                    <label for="email">Email <span class="required">*</span></label>
                                    <input id="email" name="email" type="email" value="" size="30" maxlength="100" aria-describedby="email-notes" required='required' />
                                </p>
                                <p class="comment-form-url">
                                    <label for="url">Website</label>
                                    <input id="url" name="url" type="url" value="" size="30" maxlength="200" />
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input id="wp-comment-cookies-consent" name="wp-comment-cookies-consent" type="checkbox" value="yes" /> 
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input name="submit" type="submit" id="submit" class="submit" value="Post Comment" />
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <div id="secondary" class="widget-area">
                        <app-blog-search></app-blog-search>
                        <app-blog-recent-post></app-blog-recent-post>
                        <app-blog-recent-comment></app-blog-recent-comment>
                        <app-blog-archive></app-blog-archive>
                        <app-blog-category></app-blog-category>
                        <app-blog-meta></app-blog-meta>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->