<section class="iq-pb-55">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <app-blog-owl [blogList]="List"></app-blog-owl>
            </div>
        </div>
    </div>
</section>
