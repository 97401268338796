<section >
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-right" alt="QLOUD">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-4 wow fadeInLeft">
                <img src="./assets/images/others/08.png" class="img-fluid" alt="qloud">
            </div>
            <div class="col-lg-6 col-sm-12 wow fadeInRight">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>  
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="#">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>