<section class="blue-bg iq-help">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
            <div class="col-lg-6 text-right align-self-center">
                <div class="iq-btn-container d-inline-block mr-4">
                    <a class="iq-button iq-hover d-inline" href="#">
                        Start Your Free Trial
                    </a>
                </div>
                <div class="iq-btn-container d-inline-block">
                    <a class="iq-button iq-btn-outline d-inline" href="#"> See All Plans
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>