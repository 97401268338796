<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START qloud8 REVOLUTION SLIDER 6.1.8 -->
<app-revolution-slider8></app-revolution-slider8>
<!-- End REVOLUTION SLIDER -->
<!-- Our Clients Start -->
<app-our-client></app-our-client>
<!-- Our Clients End -->
<!-- Product Start -->
<app-product></app-product>
<!-- Product End -->
<!-- Counter Start-->
<app-counter></app-counter>
<!-- Counter End-->
<!-- Service Tab Start-->
<app-service-tab></app-service-tab>
<!-- Service Tab End-->
<!-- Resource Start-->
<app-resources></app-resources>
<!-- Resource End -->
<!-- Get Started Start-->
<app-get-started></app-get-started>
<!-- Get Started End-->
<!-- Footer start -->
<app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->