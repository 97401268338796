<!-- Header -->
<header id="main-header" class="header-main {{ classHeader ? 'header2' : '' }}">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" href="#">
            <img id="{{ logoId ? 'logo_img' : '' }}" class="img-fluid logo" src="{{ logoImg }}" alt="Qloud"/>
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="menu-btn d-inline-block" id="menu-btn">
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </span>
            <span class="ion-navicon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto w-100 justify-content-end">
              <li class="nav-item dropdown" *ngFor="let option of navItemList">
                <a class="nav-link dropdown-toggle {{ option.isCurrent !== undefined && option.isCurrent ? 'active' : '' }}" href="{{ option.href }}" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ option.title }}</a>
                <div class="dropdown-menu" *ngIf="option.submenu" aria-labelledby="navbarDropdown"  style="min-width: 800px;height: 500px;margin-left: -100px;">
                 <div style="display: flex;
                 flex-direction: row;
                 flex-wrap: nowrap;
                 justify-content: flex-start;
                 align-items: stretch;
                 align-content: stretch;border-radius: 10px;">
                                  <div style="min-width: 600px;height: 400px;background-color: white; padding-top: 35px;height: 100px;margin-left: 0px !important;" class="row color">
                                    <div class="col-2 color" style="margin-bottom: 10px;">
                                      <img src="https://www.dreamhost.com/assets/icon.wordpress.shared4-44ee9204dd5b6f1fd02bb85f503901b87162b6d521563375a257ca43a411a485.svg" style="width: 50px;">

                                    </div>
                                    <div class="col-10 color" style="font-size: 12px;margin-left: -10px;margin-bottom: 10px;">
                                      <p class="menu-item-heading t-1 t-heading t-bold m-0">WordPress Basic <span class="t-c-w100 menu-pill-label m-left-0" style="background-color: #9888fd;padding:3px 6px;font-size:10px;border-radius:7px;margin-top:1px;"><i class="fal fa-tag" aria-hidden="true"></i> On Sale</span></p>
                                      <p class="menu-item-subheading t-0 t-body t-regular m-0 t-c-g500"><span>The classic way to get all the essentials.</span> ​<span class="avoidwrap">
                                        <strong>
                                            Starts at $2.59/mo
                                        </strong></span></p>
                                      </div>
                                      <div class="col-2 color" style="margin-bottom: 10px;">
                                        <img src="https://www.dreamhost.com/assets/icon.woocommerce.hosting-7bd5680210d653d41800a641d7c868203958ae68c07ff2012753e692c62999de.svg" style="width: 50px;">
  
                                      </div>
                                      <div class="col-10 color" style="font-size: 12px;margin-left: -10px;margin-bottom: 10px;">
                                        <p class="menu-item-heading t-1 t-heading t-bold m-0">WordPress Basic <span class="t-c-w100 menu-pill-label m-left-0" style="background-color: #9888fd;padding:3px 6px;font-size:10px;border-radius:7px;margin-top:1px;"><i class="fal fa-tag" aria-hidden="true"></i> On Sale</span></p>
                                        <p class="menu-item-subheading t-0 t-body t-regular m-0 t-c-g500"><span>The classic way to get all the essentials.</span> ​<span class="avoidwrap">
                                          <strong>
                                              Starts at $2.59/mo
                                          </strong></span></p>
                                        </div>
                                      <div class="col-2 color" >
                                        <img src="https://www.dreamhost.com/assets/icon.managed.wordpress-8ffdfebad7df16021af0a9064638c687942c5c02bd07dbc5cce738a54a2720be.svg" style="width: 50px;">
  
                                      </div>
                                      <div class="col-8 color" style="font-size: 12px;margin-left: -10px;">
                                        <p class="menu-item-heading t-1 t-heading t-bold m-0">WordPress Basic <span class="t-c-w100 menu-pill-label m-left-0" style="background-color: #9888fd;padding:3px 6px;font-size:10px;border-radius:7px;margin-top:1px;"><i class="fal fa-tag" aria-hidden="true"></i> On Sale</span></p>
                                        <p class="menu-item-subheading t-0 t-body t-regular m-0 t-c-g500"><span>The classic way to get all the essentials.</span> ​<span class="avoidwrap">
                                          <strong>
                                              Starts at $2.59/mo
                                          </strong></span></p>
                                        </div>
                                        <br><br>
                                        <ul style="margin-top: 20px;">
                                          <li><a  id="tracking_nav_wordpress_vps" class="menu-item__small m-bottom-1 t-0 t-heading t-medium t-decoration-none l-block" style="font-size: 12px;"><i class="fas fa-play mr-2 bluetext" ></i>VPS for WordPress</a></li><br>
                                          <li><a  id="tracking_nav_wordpress_dedicated" class="menu-item__small m-bottom-1 t-0 t-heading t-medium t-decoration-none l-block" style="font-size: 12px;"><i class="fas fa-play mr-2 bluetext" ></i>Dedicated for WordPress</a></li><br>
                                          <li><a  id="tracking_nav_wordpress_wordpress" class="menu-item__small m-bottom-1 t-0 t-heading t-medium t-decoration-none l-block" style="font-size: 12px;"><i class="fas fa-play mr-2 bluetext" ></i>Compare All WordPress Solutions</a></li>
                                      </ul>
                                      <div class="iq-btn-container d-inline-block mr-4" style="background-image: url('https://www.dreamhost.com/assets/migrationpeople2-b62a3c72917c9f931ef198fbe70ddca5b76b32b6eeca6852d8a72940145fe4ea.jpg');background: #005aa7; /* fallback for old browsers */
                                      background: -webkit-linear-gradient(to right, #005aa7, #e7ffe4); /* Chrome 10-25, Safari 5.1-6 */
                                      background: linear-gradient(to right, #005aa7, #e4ffe4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */;margin-left:15px;width:550px;border-radius:10px;padding: 3%;font-size: 13px;">
                                        <span class="t-c-w100 menu-pill-label m-left-0" style="background-color: #9888fd;padding:3px 6px;font-size:10px;border-radius:7px;margin-top:1px;">FREE</span>
                                        <p >Automated WordPress Migrations</p>
                                        <p style="margin-top: -15px;">Our free, automated migration plugin makes moving to DreamHost a breeze.</p>
                                        <a  class="iq-button iq-btn-outline d-inline" style="margin-top: -15px;border: white 1px solid;padding: 1%;color: white;">Learn More</a>
                                    </div>
                                  </div>
                                  
                                  <div style="min-width:300px;height: 500px;background-color: #F4F6F9 !important">
                                    <div class="l-inline-block">
                            
                                      <p class="t-2 t-heading t-regular m-bottom-half" style="padding-left: 35px;
                                      padding-right: 35px;padding-top: 35px;"><strong>WordPress</strong> Done Right</p>
                                      <p class="t-0 m-0" style="font-size: 13px; padding-left: 35px;">With 20+ years experience hosting over 1.5 million websites, blogs, and online stores — there’s no better place to power your WordPress site.</p>
                      
                      
                      
                                      <!-- Academy Links-->
                                      <div class="menu-academy-links m-top-3" >
                                          <p class="academy-link-heading t-body t-bold m-bottom-1 t-c-b700"><i class="" aria-hidden="true" style=" padding-left: 35px;padding-top: 65px;"></i> DreamHost Academy Articles</p>
                                          <ul class="List" style="font-size: 12px;margin-top: -35px;;padding-left: 35px;">
                                              <li style="font-size: 12px;"><a  id="tracking_nav_wordpress_what-is-wordpress" class="menu-item__small m-bottom-1 t-0 t-decoration-none l-block" style="font-size: 12px;"><span class="right-arrow"></span >What is WordPress?</a></li>
                                              <li style="font-size: 12px;"><a  id="tracking_nav_wordpress_basics" class="menu-item__small m-bottom-1 t-0 t-decoration-none l-block" style="font-size: 12px;"><span class="right-arrow"></span>Get Started with WordPress Basics</a></li>
                                              <li style="font-size: 12px;"> <a  id="tracking_nav_wordpress_managed-vs-shared" class="menu-item__small m-bottom-1 t-0 t-decoration-none l-block" style="font-size: 12px;"><span class="right-arrow"></span>Managed vs Shared WordPress Hosting</a></li>
                                              <li style="font-size: 12px;"><a  id="tracking_nav_wordpress_tutorials" class="menu-item__small m-bottom-1 t-0 t-decoration-none l-block" style="font-size: 12px;"><span class="right-arrow"></span>See All 42 WordPress Tutorials</a></li>
                                          </ul>
                                      </div>
                                  </div>
                                 </div>   
                 <!--
                  <mat-card style="width:200px;height: 200px; " *ngFor="let child of option.submenu" class="dropdown-item" [routerLink]="child.href" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                    <img src="../../../../assets/images/blog/01.png">
                   <p> {{ child.title }}</p></mat-card>
                  -->
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="sub-main">
            <ul>
              <li class="d-inline"><a href="javascript:void(0)" class="login iq-button iq-btn-outline iq-btn-round" target="_blank">Login</a></li>
              <li class="d-inline"><a href="javascript:void(0)" class="signup iq-button default iq-btn-round" target="_blank">Sign Up</a></li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Header End -->