<section class="iq-pb-55">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 mb-lg-0 mb-5 align-self-center wow fadeInUp" data-wow-delay="0.4s">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                    </div>
                </div>
                <div class="row">
                    <div *ngFor="let list of List1" class="col-lg-3 col-md-3 col-sm-12">
                        <div class="iq-counter">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                    <span class="timer" [attr.data-to]="list.value" data-speed="5000">{{ list.value }}</span>
                                    <span class="counter-after-content"></span>
                                    <span class="counter-symbol">{{ list.symbol }}</span></p>
                                <p class="counter-content-text">{{ list.title }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="iq-btn-container mt-4">
                    <a class="iq-button iq-btn-medium iq-btn-round iq-btn-flat d-inline"
                        [routerLink]="['/about-us-1']">Read More
                    </a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.4s">
                        <div *ngFor="let list of List2" class="iq-process iq-process-step-style-1 ">
                            <div class="iq-process-step">
                                <div class="iq-step-content">
                                    <img class="hover-img img-fluid" src="{{ list.image }}" alt="fancybox">
                                </div>
                                <div class="iq-step-text-area">
                                    <h4 class="iq-step-title">{{ list.title }}</h4>
                                    <span class="iq-step-desc">{{ list.desc }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
                        <div *ngFor="let list of List3" class="iq-process iq-process-step-style-1 ">
                            <div class="iq-process-step">
                                <div class="iq-step-content">
                                    <img class="hover-img img-fluid" src="{{ list.image }}" alt="fancybox">
                                </div>
                                <div class="iq-step-text-area">
                                    <h4 class="iq-step-title">{{ list.title }}</h4>
                                    <span class="iq-step-desc">{{ list.desc }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>