import { trigger, transition, query, animateChild, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-plan',
  templateUrl: './pricing-plan.component.html',
  styleUrls: ['./style.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter, :leave', [
        query('@*', animateChild())
      ])
    ]),
    trigger('easeInOut', [
      transition('void => *', [
          style({
              opacity: 0
          }),
          animate("500ms ease-in", style({
              opacity: 1
          }))
      ]),
      transition('* => void', [
          style({
              opacity: 1
          }),
          animate("500ms ease-in", style({
              opacity: 0
          }))
        ])
      ])
  ]
})
export class PricingPlanComponent implements OnInit {
verif=false
  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'CHOISISSEZ LE PLAN PARFAIT POUR VOTRE PROJET   ',
    subTitle: 'Hébergement web Tunisie  ',
    description :'Hébergement site Web Tunisie Simple, rapide et sécurisé. Confiez nous l’hébergement de votre site web dès maintenant.'
  };
  items: any[] = [
    {
      title: "Economoqie",
      soustitle:"Offre pour un seul site",
      description: "It is a long established fact that a reader will be distracted",
      price: "3,86",
      plan_desc: "par mois",
      duration: '0.6s',
      href: "#",
      services: [ { title: "Feedback System" }, { title: "Social Integration" } ,{ title: "Push Notifications " },{ title: "Relevant App Content" } ,{ title: "Log-in via Social Media " } ]
    },
    {
      title: "Zen",
      soustitle:"Offre pour plusieurs sites",

      description: "It is a long established fact that a reader will be distracted",
      price: "6,99",
      plan_desc: "par mois",
      duration: '0.6s',
      href: "#",
      services: [ { title: "Feedback System" }, { title: "Social Integration" } ,{ title: "Push Notifications " },{ title: "Relevant App Content" } ,{ title: "Log-in via Social Media " } ]
    },
    {
      title: "Professionnel",
      soustitle:"Performance sans égaux",

      description: "It is a long established fact that a reader will be distracted",
      price: "15,99",
      plan_desc: "par mois  <span style='background: #dc3545; padding-left: 4px padding-right: 4px;      border-radius: 4px;      color: white;      font-size: 13px;      font-weight: 550;'>-15%</span>",
      active: true,
      duration: '1.2s',
      href: "#",
      services: [ { title: "Feedback System" }, { title: "Social Integration" } ,{ title: "Push Notifications " },{ title: "Relevant App Content" } ,{ title: "Log-in via Social Media " } ]
    },
    {
      title: "E-commerce",
      soustitle:"L'univers E-commerce",

      description: "It is a long established fact that a reader will be distracted",
      price: "19,99",
      plan_desc: "par mois",
      duration: '1.8s',
      href: "#",
      services: [ { title: "Feedback System" }, { title: "Social Integration" } ,{ title: "Push Notifications " },{ title: "Relevant App Content" } ,{ title: "Log-in via Social Media " } ]
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
