<section class="iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
            <div class="row">
                <div *ngFor="let list of List" class="col-lg-4 col-md-6">
                    <div class="iq-icon-box iq-icon-box-style-4 text-center wow fadeInUp" data-wow-delay="0.2s">
                        <div class="icon-box-img">
                            <img src="{{ list.image }}" class="img-fluid" alt="Qloud">
                        </div>
                        <div class="icon-box-content">
                            <h5 class="icon-box-title"><a href="#">{{ list.title }}</a></h5>
                            <p class="icon-box-desc">{{ list.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
