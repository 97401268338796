import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html'
})
export class BusinessComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: ' Utilisé par les entreprises les plus innovantes',
    subTitle: 'Témoignage',
    titleIcon: ''
  };

  Image1: any[] = [
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/psg.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Bountou1x2.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/beautystore.png'},
  ];

  Image2: any[] = [
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/attijari-bank-tunisie.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/miralina.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/medina-1.png'},
  ];

  List: any[] = [
    { name: 'JD Scot', image: './assets/images/testimonial/01.png' , design: 'Designer, Qloud' , desc: 'Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley.' },
    { name: 'Fitt Morgan', image: './assets/images/testimonial/02.png' , design: 'Designer, Qloud' , desc: 'Dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown.' },
    { name: 'Nik Jorden', image: './assets/images/testimonial/03.png' , design: 'Designer, Qloud' , desc: 'Text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley .' },
    { name: 'Julia Cooper', image: './assets/images/testimonial/04.png' , design: 'Designer, Qloud' , desc: 'Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer.' },
    
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
