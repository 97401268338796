<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Breadcrumb Start -->
<app-top-banner [data]="data"></app-top-banner>
<!-- Breadcrumb End -->
<!-- Counter Start -->
<app-counter></app-counter>
<!-- Counter End -->
<!-- Service Start-->
<app-nav-tabs></app-nav-tabs>
<!-- Service End-->
<!-- Application start-->
<app-your-application></app-your-application>
<!-- Application End-->
<!-- Blog Start-->
<app-our-blog></app-our-blog>
<!-- Blog End-->
<!-- Footer start -->
    <app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->