<section class="iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-12 mb-lg-0 mb-4">
                <div class="row">
                    <div *ngFor="let list of List1" class="col-lg-12 col-md-6">
                        <div class="iq-icon-box iq-icon-box-style-8  text-center  wow fadeInLeft">
                            <div class="icon-box-img">
                                <img src="{{ list.image }}" class="img-fluid mb-3" alt="qloud"> </div>
                            <div class="icon-box-content">
                                <h5 class="icon-box-title"> <a href="{{ list.link }}">{{ list.title }}</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 mb-lg-0 mb-4">
                <div class="iq-fancy-box iq-resorces  text-left active wow fadeInUp ">
                    <div class="iq-fancy-box-content">
                        <div class="iq-img-area">
                            <img src="./assets/images/others/04.png" class="img-fluid" title="img1" alt="qloud"> </div>
                        <div class="media-body">
                            <h5 class="iq-fancy-title"> Increase Performance With Balancers</h5>
                            <p class="fancy-box-content mb-0"> It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="row">
                    <div *ngFor="let list of List2" class="col-lg-12 col-md-6">
                        <div class="iq-icon-box iq-icon-box-style-8  text-center  wow fadeInRight">
                            <div class="icon-box-img">
                                <img src="{{ list.image }}" class="img-fluid mb-3" alt="qloud"> </div>
                            <div class="icon-box-content">
                                <h5 class="icon-box-title"> <a href="{{ list.link }}">{{ list.title }}</a> </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>