<section>
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-left" alt="QLOUD">
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-right" alt="1">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="javascript:void(0)">Commencez</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/client-area-zenhostinng-2048x1697.png" class="img-fluid" alt="qloud">
            </div>
        </div>
    </div>
</section>
