import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html'
})
export class SecurityComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center mb-0 iq-title-box-2',
    title: 'Hébergement pour toute application, toute plate-forme',
    subTitle: 'Security',
    titleIcon: ''
  };

  Sliders: any[] = [
    {
      id : 'Google-Cloud-Platform',
      title: 'Hébergement wordpress tunisie',
      desc : 'Hébergement Web Simple, rapide et sécurisé. Optimisé pour wordpress Choisissez votre hébergement et bénéficiez d’une configuration et de solutions d’accélération optimisés pour WordPress.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/wordpress-hosting.png',
      prix :'3,86'
    },
    {
      id : 'docker',
      title: 'Hébergement PHP Tunisie',
      desc : 'Vendez vos produits sur internet en réalisant un site e-commerce performant à faible coût et par vos propres moyens à l’aide des fonctionnalités apportées par le module PrestaShop.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/php-hosting.png',
      prix :'10,86'
    },
    {
      id : 'kubernetes',
      title: 'Hébergement Java Tunisie',
      desc : 'Hébergement JAVA Sécurisé et Ultra-Rapide. Hébergement Tomcat, JBoss, GlassFish, WildFly, TomEE, ou Jetty. Hébergement JAVA J2EE professionnel ',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/java-hosting.png',
      prix :'13,86'
    },
    {
      id : 'Openshift',
      title: 'Hebergement Ruby',
      desc : 'The placeholder text, beginning with the line “Lorem ipsum dolor sit amet, consectetur adipiscing elit”, looks like Latin because in its youth, centuries ago, it was Latin',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Ruby-hosting.png',
      prix :'15,86'
    },
    {
      id : 'AWS-2',
      title: 'Hébergement C et C++',
      desc : 'The placeholder text, beginning with the line “Lorem ipsum dolor sit amet, consectetur adipiscing elit”, looks like Latin because in its youth, centuries ago, it was Latin',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/hebergement-c.png',
      prix :'30,86'
    },
    
    {
      id : 'azure1',
      title: 'Hébergement NodeJs Tunisie',
      desc : 'Hébergement NodeJS. Concentrez vous sur votre code, l’hébergement cloud NodeJS devient facile et instantané.  découvrez nos offres des maintenant.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/go-hosting.png',
      prix :'18,86'
    },
    {
      id : 'azure2',
      title: 'Hébergement NodeJs Tunisie',
      desc : 'Hébergement NodeJS. Concentrez vous sur votre code, l’hébergement cloud NodeJS devient facile et instantané.  découvrez nos offres des maintenant.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/mongodb-hosting.png',
      prix :'18,86'
    },
    {
      id : 'azure3',
      title: 'Hébergement NodeJs Tunisie',
      desc : 'Hébergement NodeJS. Concentrez vous sur votre code, l’hébergement cloud NodeJS devient facile et instantané.  découvrez nos offres des maintenant.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/python-hosting.png',
      prix :'18,86'
    },
    {
      id : 'azure',
      title: 'Hébergement NodeJs Tunisie',
      desc : 'Hébergement NodeJS. Concentrez vous sur votre code, l’hébergement cloud NodeJS devient facile et instantané.  découvrez nos offres des maintenant.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/nodejs.png',
      prix :'18,86'
    },
    {
      id : 'unnamed-11-2',
      title: 'React, Angular, Vuejs ...',
      desc : 'hébergez vos application utilisant des  framework JS dans des serveurs ultras rapide et sécurisé , En un clic de bouton, vous pouvez déployer une application.',
      link: '#',
      image : 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/js-hosting.png',
      prix :'18,86'
    }
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
