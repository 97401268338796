<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START REVOLUTION SLIDER -->
<app-revolution-slider3></app-revolution-slider3>
<!-- END REVOLUTION SLIDER -->
    <!-- Main-Content Start -->
    <div class="main-content">
        <!-- Icon-box Start -->
      
        <!-- Icon-box End -->
        <!-- About Us Start -->
        <app-about-us></app-about-us>
        <!-- About Us End -->
        <!-- Process Start-->
     
        <!-- Process End-->
        <!-- Security Start-->
      
        <!-- Security End-->
        <!-- Features Start -->
        <!-- Features End -->
        <!-- Client Start -->
        <app-our-client></app-our-client>
        <!-- Client End -->
        <!-- Testimonial Start -->
        <app-products></app-products>

        <!-- Testimonial End -->
        <!-- Contact Start -->
        <app-project></app-project>
        <!-- Contact End -->
        <!-- Blog Start -->
        <app-our-blog></app-our-blog>
        <!-- Blog End -->
        <app-process></app-process>
        <app-features></app-features>
        <app-security></app-security>
       <app-business></app-business>
       <app-get-started></app-get-started>

       <!--Start of Tawk.to Script-->

    <!--End of Tawk.to Script-->
    </div>
    <!-- Footer start -->
    <app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
    <!-- Footer end -->
