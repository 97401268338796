<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START qloud5 REVOLUTION SLIDER 6.1.8 -->
<app-revolution-slider5></app-revolution-slider5>
<!-- End REVOLUTION SLIDER -->
<!-- Main-Content Start -->
<div class="main-content">
    <!-- About us Start-->
    <app-about-us></app-about-us>
    <!-- About us End-->
    <!-- Product Start-->
        <app-products></app-products>
    <!-- Product End-->
    <!-- Our testimonial Start -->
        <app-our-testimonial></app-our-testimonial>
    <!-- Our testimonial End -->
    <!-- Our Clients Start -->
    <app-our-clients></app-our-clients>
    <!-- Our Clients End -->
    <!-- Resources Start -->
    <app-resources></app-resources>
    <!-- Resources End -->
    <!-- Get Started Start-->
    <app-get-started></app-get-started>
    <!-- Get Started End-->

</div>
<!-- Main-Content End -->
<!-- Footer start -->
<app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->