<section class="iq-getstarted" style="background-image: linear-gradient(180deg, #F0FAFE 0%, #FFFFFF 100%);">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 align-self-center wow fadeInUp">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                <div class=" text-left iq-title-box iq-title-default iq-title-box-1">
                    <div class="iq-title-icon"></div>
                    <h4 class="iq-title">Hébergez vos projets
                    </h4>
                    <p class="iq-title-desc">Nouveau chez Zenhosting? Crée votre compte en ligne en quelque click.</p>
                </div>
                <div class="iq-btn-container">
                    <a class="iq-button d-inline" [routerLink]="['/contact-us-1']">Créer un compte</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mt-4 mt-lg-0 wow fadeInUp">
                <div class=" text-left iq-title-box iq-title-default iq-title-box-1">
                    <div class="iq-title-icon"></div>
                    <h4 class="iq-title">Besoin d'aide?</h4>
                    <p class="iq-title-desc">Nos experts vous aideront à construire la bonne solution ou à trouver le bon partenaire pour vos besoins.</p>
                </div>
                <div class="iq-btn-container d-inline-block mr-4">
                    <a class="iq-button iq-btn-outline d-inline" [routerLink]="['/contact-us-1']"> Contacter nous </a>
                </div>
                
                <div class="iq-btn-container d-inline-block mr-4">
                    <a class="iq-button iq-btn-outline d-inline" [routerLink]="['/contact-us-1']"> Live chat </a>
                </div>
            </div>
        </div>
    </div>
</section>