import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./style.scss']

})
export class ProductsComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'Découvrez notre large gamme de produits',
    subTitle: 'Products',
    titleIcon: '',
    description: 'Nous avons des clients formidables qui adorent nos produits et nous voulons faire connaître et partager leur enthousiasme.'

  };

  List: any[] = [
    { title: 'Hébergement Web', btnText: 'A partir de 3,83 DT/mois', image: './assets/images/product/01.png', desc: 'Hébergement cloud Simple, rapide et sécurisé..' },
    { title: 'Serveur VPS', btnText: 'A partir de 14 DT/mois', image: './assets/images/product/02.png', desc: 'La magie du déploiement de machines virtuelles en quelques clic.' },
    { title: 'H E-Commerce', btnText: 'A partir de 19,9 DT/mois', image: './assets/images/product/03.png', desc: 'Hébergement E-commerce Simple, rapide et sécurisé.' },
    { title: 'Serveur Dédié', btnText: 'A partir de 140 DT/mois', image: './assets/images/product/04.png', desc: 'une large gamme de serveurs dédiés à la carte.' },
    { title: 'H Revendeur', btnText: 'A partir de 19,9 DT/mois', image: './assets/images/product/03.png', desc: 'Payer un seul hébergement pour tous vos sites web.' },
    { title: 'Certificat SSL', btnText: 'A partir de 50 DT/mois', image: './assets/images/product/04.png', desc: 'Sécuriser Votre Site web avec les certificats Comodo.' }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
