<section style="background-color: #f2f5fe;height: 380px;">
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-right" alt="QLOUD" style="width: 350px;">
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-left" alt="QLOUD" style="width: 350px;">

    <div class="container" >
        <p style="text-align: center;font-size: 33px;color:#142149;    font-family: 'TeXGyreAdventor-Bold', sans-serif;">Recherchez votre Future domain</p>
        <div class="row">
            <div class="col-lg-2 col-sm-12">

              
            </div>
            <div class="col-lg-8 mb-5 mb-lg-0 text-center text-lg-right">
                <form class="mc4wp-form mc4wp-form-517" method="post">
                    <div class="mc4wp-form-fields">
                        <input  placeholder="rechercher votre domain" required="" style="background-color: white;">
                        <input type="submit" value="search" style="text-align: center;">
                    </div>
                </form>
            </div>
            
        </div>
        <div class="row">
            <div class="col-lg-2 col-sm-12">

              
            </div>
            <div class="col-lg-8 col-sm-12 mb-lg-0 mb-5">
               

                <div class="elementor-widget-container" style="margin-top:50px;display: flex;">
                    <div class="iq-icon-box iq-icon-box-style-2  " >
                
                <div class="icon-box-img" >
                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/11/iq-org.png" title="iq-org" alt="iq-org" style="width: 80px;">	</div>
                   
                <div class="icon-box-content">
                               
                           <p class="icon-box-desc">&nbsp; $8.99 </p>
                            </div>
                
                </div>
                <div class="iq-icon-box iq-icon-box-style-2  ">
                
                    <div class="icon-box-img">
                    <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/11/iq-net.png" title="iq-org" alt="iq-org" style="width: 80px;">	</div>
                       
                    <div class="icon-box-content">
                                   
                               <p class="icon-box-desc">&nbsp; $8.99 </p>
                                </div>
                    
                    </div>
                    <div class="iq-icon-box iq-icon-box-style-2  ">
                
                        <div class="icon-box-img">
                        <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/11/iq-in.png" title="iq-org" alt="iq-org" style="width: 80px;">	</div>
                           
                        <div class="icon-box-content">
                                       
                                   <p class="icon-box-desc">&nbsp; $8.99 </p>
                                    </div>
                        
                        </div>
                        <div class="iq-icon-box iq-icon-box-style-2  ">
                
                            <div class="icon-box-img">
                            <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/11/iq-com.png" title="iq-org" alt="iq-org" style="width: 80px;">	</div>
                               
                            <div class="icon-box-content">
                                           
                                       <p class="icon-box-desc">&nbsp; $8.99 </p>
                                        </div>
                            
                            </div>
                            <div class="iq-icon-box iq-icon-box-style-2  ">
                
                                <div class="icon-box-img">
                                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/11/iq-co.png" title="iq-org" alt="iq-org" style="width: 80px;">	</div>
                                   
                                <div class="icon-box-content">
                                               
                                           <p class="icon-box-desc">&nbsp; $8.99 </p>
                                            </div>
                                
                                </div>
                </div>

            </div>
            <div class="col-lg-2 col-sm-12">

              
            </div>
            
        </div>
    </div>
</section>


