<section class="iq-fancy-box-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center iq-title-box iq-title-default iq-title-box-2 wow fadeInUp" data-wow-duration="0.6s">
          <div class="iq-title-icon">
          </div>
          <span class="iq-subtitle">Process</span>
          <h2 class="iq-title">How It's Work</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-md-6 mb-lg-0 mb-5 mt-lg-5">
        <div class="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp" data-wow-duration="0.6s">
          <div class="iq-img-area">
            <img src="assets/images/fancy-box/01.png" class="img-fluid" alt="QLOUD"> </div>
          <div class="iq-fancy-box-content">
            <h5 class="iq-fancy-title"> Cloud Compute </h5>
            <p class="fancy-box-content"> It is a long established fact that a reader will be distracted by the of
              readable content at scale on the.
            </p>
            <div class="iq-btn-container">
              <a class="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">
                Read More<i aria-hidden="true" class="ion ion-ios-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mb-lg-0 mb-5 mt-lg-5">
        <div class="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp" data-wow-duration="1.2s">
          <div class="iq-img-area">
            <img src="assets/images/fancy-box/02.png" class="img-fluid" alt="QLOUD"> </div>
          <div class="iq-fancy-box-content">
            <h5 class="iq-fancy-title">Block Storage</h5>
            <p class="fancy-box-content"> It is a long established fact that a reader will be distracted by the of
              readable content Deploy at scale on the.
            </p>
            <div class="iq-btn-container">
              <a class="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">
                Read More<i aria-hidden="true" class="ion ion-ios-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 mt-lg-5">
        <div class="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp " data-wow-duration="1.8s">
          <div class="iq-img-area">
            <img src="assets/images/fancy-box/03.png" class="img-fluid" alt="QLOUD"> </div>
          <div class="iq-fancy-box-content">
            <h5 class="iq-fancy-title">Dedicated Cloud</h5>
            <p class="fancy-box-content"> It is a long established fact that a reader will be distracted by the of
              readable content Deploy at scale on the.
            </p>
            <div class="iq-btn-container">
              <a class="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">
                Read More<i aria-hidden="true" class="ion ion-ios-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>