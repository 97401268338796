import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html'
})
export class QuestionsComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'Questions fréquemment posées    ',
    subTitle: 'Faq',
    titleIcon: '',
    description:'Nos conseillers commerciaux et techniques se tiennent à votre disposition pour répondre à toutes vos questions les plus exigeants.    '
  };

  List: any[] = [
    { question: "Hébergement site web , qu'est-ce que c'est ?", answer:' C’est un concept permettant d’accueillir, sur un même serveur, plusieurs sites web appartenant à des utilisateurs différents. Pour ce faire, l’hébergement web mutualisé fait appel à des installations techniques puissantes (notamment les processeurs, mémoires vives, espaces disques…) pouvant stocker les données de plusieurs sites web. Ceux-ci sont administrés, non pas par le titulaire de l’hébergement, mais par une tierce personne. Les serveurs informatiques utilisés sont accessibles via une connexion spécifique (des outils d’administration propriétaires, open-source…). La puissance de ces serveurs permet un hébergement web illimité pour les différents utilisateurs.', active: true },
    { question: 'Combien de sites Web puis-je héberger?', answer:'Avec un plan d’hébergement Economique vous pouvez héberger un site Web , un excellent point de départ! Si vous cherchez à gérer une poignée de sites, un plan partagé illimité vous permet d’héberger n’importe quel nombre de sites Web (nous disons illimité et nous le pensons!). Quelle que soit la flexibilité dont vous avez besoin, DreamHost la propose.'},
    { question: 'Que signifie un enregistrement de domaine gratuit?    ', answer:'Zenhosting est heureux d’offrir à ses clients un enregistrement de nom de domaine gratuit (.tn , .com.tn , fr ) lors de la réservation d’un forfait d’hébergement d’un an ou plus. L’offre d’enregistrement de domaine gratuit est valide pour tous les forfaits annuels au cours de votre première année à titre de client Zenhosting.'},
    { question: "j'ai acheté un forfait d'hébergement site web Tunisie je fais quoi maintenant?", answer:'Bienvenue dans la famille Zenhosting! Après votre adhésion aux services Zenhosting, vous recevrez deux messages électroniques à l’adresse que vous aurez inscrite au formulaire d’adhésion : un email d’adhésion et un avis de facturation. Une fois votre paiement traité, vous recevrez votre email de bienvenue, lequel contiendra vos informations d’accès (soit toutes les informations dont vous aurez besoin pour accéder à votre compte d’hébergement Zenhosting), ainsi que des liens utiles afin de vous aider à démarrer. Lorsque vous recevez cet email, votre compte d’hébergement est prêt à être utilisé.'},
    { question: "Puis-je mettre à jour mon plan d'hébergement au fur et à mesure que mon site web grandit?    ", answer:' Nous savons que votre objectif ultime est probablement de développer votre site Web au fil du temps. Lorsque votre site a simplement besoin de technologies et de fonctionnalités plus puissantes, vous pouvez passer à un plan supérieur en quelques clics. Bien que l’hébergement partagé soit une option intelligente et économique lorsque vous êtes débutant, un site riche en contenu et à fort trafic peut nécessiter un plan à plus grande échelle, ce qui signifie un espace serveur supplémentaire, des ressources spécialement dédiées, une sécurité avancée et une accélération. Vous vous demandez quand effectuer une mise à niveau? Si vous remarquez une diminution des performances du site ou un manque d’espace ou de ressources nécessaires, il est temps d’envisager de faire passer votre site au niveau supérieur et de choisir un pack d’hébergement mieux adapté. Quelle que soit la taille actuelle de votre site Web, Zenhosting vous propose un plan parfaitement adapté. Nous nous adaptons à vos besoins, ce qui facilite la mise à niveau.    '},
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
