<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Breadcrumb Start -->
<app-top-banner [data]="data"></app-top-banner>
<!-- Breadcrumb End -->

<!-- Features Start-->
<app-features></app-features>
<!-- Features End-->
<!-- Working Language Start-->
<app-working-language></app-working-language>
<!-- Working Language End-->
<!-- About Us Start-->
<app-about-us></app-about-us>
<!-- About Us End-->
<!-- Icon Box Start -->
<app-icon-box></app-icon-box>
<!-- Icon Box End -->
<!-- Blog Start-->
<app-our-blog></app-our-blog>
<!-- Blog End-->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->