<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Main-Content Start -->
<div class="main-content">
    <!-- Breadcrumb Start -->
    <app-top-banner [data]="data"></app-top-banner>
    <!-- Breadcrumb End -->
    <!-- Contact Start -->
        <app-contact-us></app-contact-us>
    <!-- Contact End -->
    
</div>
    <!-- Contact Card Start -->
    <app-contact-card></app-contact-card>
    <!-- Contact CardEnd -->

<!-- Main-Content End -->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->