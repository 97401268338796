<!-- Features Start -->
<section class="iq-fancy-box-section">
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-left" alt="QLOUD">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center iq-title-box iq-title-default iq-title-box-2 wow fadeInUp" data-wow-duration="0.6s">
                    <div class="iq-title-icon">
                    </div>
                    <span class="iq-subtitle">How It's Work</span>
                    <h2 class="iq-title">Instant Deploys For All Your Apps</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 {{  i < List.length - 1  ? 'mb-lg-0 mb-5' : '' }}">
                <div class="iq-process iq-process-step-style-6 text-center wow fadeInUp" data-wow-duration="0.6s">
                    <div class="iq-process-step">
                        <img *ngIf="list.arrow && list.arrow !== undefined" class="iq-before-img img-fluid" src="{{ list.arrow }}" alt="arrow-img">
                        <div class="iq-step-content">
                            <img class="hover-img img-fluid" src="{{ list.images }}" alt="fancybox">
                        </div>
                        <div class="iq-step-text-area">
                            <h4 class="iq-step-title mt-3 mb-3">{{ list.title }}</h4>
                            <span class="iq-step-desc">{{ list.desc }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>