<section class="iq-testimonial-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 align-self-center">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 align-self-center">
                <app-testimonials></app-testimonials>
            </div>
        </div>
    </div>
</section>