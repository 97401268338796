<section class="iq-blog-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
              <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Theme_collage_desktop__1_-min.jpg">
            </div>
        </div>
    </div>
</section>

