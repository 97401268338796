import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html'
})
export class ProductComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'Vous avez besoin de plus de ressources ?    ',
    subTitle: 'Product',
    titleIcon: '',
    description:'Que vous soyez blogueur, propriétaire de petite entreprise, concepteur Web ou développeur, Zenhosting vous permet de démarrer facilement votre voyage en ligne.    '
  };

  List: any[] = [
    {
      title: 'Zen',
      description: 'Offre pour plusieurs sites.      ',
      services: [ { title: '15 sites' }, { title: '10 DB' },{ title: '10 Emails' } ]
, active: true,
icon: 'itab-icon fa fa-rocket',
    },
    {
      title: 'E-commerce',
      description: 'Offre pour plusieurs sites.      ',
      services: [ { title: '15 sites' }, { title: '10 DB' },{ title: '10 Emails' } ]
    },
    {
      title: 'VPS',
      description: 'Offre pour plusieurs sites.      ',

      services: [ { title: '15 sites' }, { title: '10 DB' },{ title: '10 Emails' } ]
    }
  ]
  List2: any[] = [
    {
      id: 'tab-compute-engine',
      title: 'E-commerce',
      icon: 'ion ion-android-call',
      image: './assets/images/tab/05.png',
      sub_title:"Compute Engine",
      desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour',
      active: true
    },
    {
      id: 'tab-application-service',
      title: 'Serveur Cloud',
      icon: 'fa fa-pie-chart',
      sub_title:"Real-Time Actionable Insights",
      desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour',
      services: [ { title: 'Boost SEO ranking' },{ title: 'Social Sharing' },{ title: 'Marketing' },{ title: 'Retention' },{ title: 'Visual Reviews' },{ title: 'Reviews Generation' } ],
      link: '/about-us-1'
    },
    {
      id: 'tab-migrate',
      title: 'Serveur Dédié',
      icon: 'fa fa-user',
      sub_title:"Qloud As A Lead Magnet",
      desc: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour',
      services: [ { title: 'Boost SEO ranking' },{ title: 'Social Sharing' },{ title: 'Marketing' },{ title: 'Retention' },{ title: 'Visual Reviews' },{ title: 'Reviews Generation' } ],
      link: '/about-us-1'
    },
    

  ];
  constructor() { }

  ngOnInit(): void {
  }
  ind=0
service(i){
this.ind=i
}
}
