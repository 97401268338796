<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->

<!-- START qloud2 REVOLUTION SLIDER 6.1.8 -->
<app-revolution-slider2></app-revolution-slider2>
<!-- END REVOLUTION SLIDER -->
<!-- MainContent -->
<div class="main-content">
    <!-- Client Start -->
    <app-our-client></app-our-client>
    <!-- Client End -->
    <!-- Features Start -->
    <app-features></app-features>
    <!-- Features End -->
    <!-- About Us Start -->
    <app-about-us></app-about-us>
    <!-- About Us End -->
    <!-- Icon-box Start -->
    <app-our-services></app-our-services>
    <!-- Icon-box End -->
    <app-working-language></app-working-language>
    <!-- Counter Start -->
    <app-counter></app-counter>
    <!-- COUNTER END -->
    <!-- Project START -->
    <app-project></app-project>
    <!-- Project END -->
    <!-- Blog START -->
    <app-our-blog></app-our-blog>
    <!-- Blog END -->
</div>
<!-- Footer start -->
<app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->
