<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Breadcrumb Start -->
<app-top-banner [data]="data"></app-top-banner>
<!-- Breadcrumb End -->
<section class="iq-blog-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="fourzero-image mb-5">
                    <img src="./assets/images/others/03.png" class="img-fluid" alt="404" />
                </div>
                <h4> 404 Error</h4>
                <p class="mb-5"> Oops! This Page is Not Found. </p>
                <div class="d-block">
                    <a class="iq-button" [routerLink]="['/landing-page1']">Back to Home</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->