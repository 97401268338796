<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Breadcrumb Start -->
<app-top-banner [data]="data"></app-top-banner>
<!-- Breadcrumb End -->
<!-- Question Start -->
<app-questions></app-questions>
<!-- Question End -->
<!-- Working Language Start -->
<app-working-language></app-working-language>
<!-- Working Language End -->
<!-- Blog start -->
<app-our-blog></app-our-blog>
<!-- Blog End -->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->