import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revolution-slider2',
  templateUrl: './revolution-slider2.component.html'
})
export class RevolutionSlider2Component implements OnInit {
  public imgSrc = './assets/revslider/assets';

  constructor() { }

  ngOnInit(): void {
  }

}
