import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revolution-slider5',
  templateUrl: './revolution-slider5.component.html'
})
export class RevolutionSlider5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
