<section class="iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <app-team-owl [List]="List1"></app-team-owl>
                <app-team-owl [List]="List2"></app-team-owl>
            </div>
        </div>
    </div>
</section>