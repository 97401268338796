
<section class="iq-counter-section iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-list  iq-two-column">
                    <ul class="iq-list-with-icon">
                        <li *ngFor="let list of List"><i class="ion ion-checkmark-round"></i>{{ list.title }}</li>
                    </ul>
                </div>
                <div class="iq-btn-container mt-4">
                    <a class="iq-button iq-btn-medium iq-btn-round iq-btn-flat d-inline" href="javascript:void(0)">Read More</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div *ngFor="let list of List1"  class="iq-counter text-center iq-box-shadow iq-counter-style-1">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                <span class="timer" [attr.data-to]="list.timer" data-speed="5000">{{ list.timer}}</span>
                                <span class="counter-after-content">{{ list.content}}</span>
                                <span class="counter-symbol">{{ list.symbol }}</span></p>
                                <h5 class="counter-title-text">{{ list.title }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div *ngFor="let list of List2"  class="iq-counter text-center iq-box-shadow iq-counter-style-1">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                <span class="timer" [attr.data-to]="list.timer" data-speed="5000">{{ list.timer}}</span>
                                <span class="counter-after-content">{{ list.content}}</span>
                                <span class="counter-symbol">{{ list.symbol }}</span></p>
                                <h5 class="counter-title-text">{{ list.title }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
