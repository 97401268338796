<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START qloud6 REVOLUTION SLIDER 6.1.8 -->
<app-revolution-slider6></app-revolution-slider6>
<!-- End REVOLUTION SLIDER -->
<!-- Databases Start -->
<app-databases></app-databases>
<!-- Databases End -->
<!-- Help start -->
<app-help></app-help>
<!-- Help End -->
<!-- Product start -->
<app-products></app-products>
<!-- Product End -->
<!-- Database option Start-->
<app-database-option></app-database-option>
<!-- Database option End-->
<!-- Business Start-->
<app-business></app-business>
<!-- Business End-->
<!-- Footer start -->
<app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->