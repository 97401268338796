<section class="pt-0">
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-right" alt="QLOUD">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-sm-12 mb-lg-0 mb-5 align-self-center">
                <div class="row">
                    <div class="col-lg-6 text-center">
                        <img *ngFor="let list of Image1" src="{{ list.image }}" class="img-fluid mb-4" alt="client-img">
                    </div>
                    <div class="col-lg-6 text-center">
                        <img *ngFor="let list of Image2" src="{{ list.image }}" class="img-fluid mb-4" alt="client-img">
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-sm-12 align-self-center">
                <div class="iq-testimonial text-left iq-testimonial-3 iq-data-testimonial">
                    <div class="owl-carousel" data-dots="true" data-nav="false" data-items="1" data-items-laptop="1"
                        data-items-tab="1" data-items-mobile="1" data-items-mobile-sm="1" data-autoplay="true"
                        data-loop="true" data-margin="30">
                        <div *ngFor="let list of List" class="iq-testimonial-info">
                            <div class="iq-testimonial-avtar">
                                <img alt="image-testimonial" class="img-fluid center-block"
                                    src="{{ list.image }}">
                            </div>
                            <div class="iq-testimonial-content">
                                <p class="pr-lg-4">{{ list.desc }}</p>
                            </div>
                            <div class="iq-testimonial-member">
                                <div class="avtar-name">
                                    <div class="iq-lead">{{ list.name }}</div>
                                    <span class="iq-post-meta">{{ list.design }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
