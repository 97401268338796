<section class="iq-fancy-box-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 {{  i < List.length - 1  ? 'mb-lg-0 mb-5' : '' }} mt-lg-5">
                <div class="iq-fancy-box iq-fancy-box-style-1 text-center iq-box-shadow wow fadeInUp" data-wow-duration="0.6s">
                    <div class="iq-img-area">
                        <img src="{{ list.image }}" class="img-fluid" alt="QLOUD">
                    </div>
                    <div class="iq-fancy-box-content">
                        <h5 class="iq-fancy-title"> {{ list.title }}  </h5>
                        <p class="fancy-box-content">{{ list.desc }}</p>
                        <div class="iq-btn-container">
                            <a class="iq-button iq-btn-link has-icon btn-icon-right d-inline" href="javascript:void(0)">Read More<i aria-hidden="true" class="ion ion-ios-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
