<section class="pt-0 wow fadeInUp" data-wow-duration="0.6s">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="iq-db-options">
                    <ul class="list-type">
                        <li class="type-1">
                            <div class="d-inline-block iq-heading ">
                                <p class="heading-title mb-0">Database Type</p>
                            </div>
                            <div class="d-inline-block iq-heading">
                                <p class="heading-title mb-0">COMMON USES</p>
                            </div>
                            <div class="d-inline-block iq-heading">
                                <p class="heading-title mb-0">GCP PRODUCT</p>
                            </div>
                            <div class="d-inline-block  iq-heading iq-res">
                                <p class="heading-title mb-0">SAMPLE CUSTOMERS</p>
                            </div>
                        </li>
                        <li class="type-1 iq-rela">
                            <div class="d-inline-block iq-heading mt-2">
                                <p class="heading-title iq-title mb-0">Relational</p>
                            </div>
                            <div class="d-inline-block iq-heading">
                                <div>
                                    Compatibility<br>
                                    Transactions<br>
                                    Complex Queries<br>
                                    Joins
                                </div>
                            </div>
                            <div class="d-inline-block iq-heading">
                                <div class="elementor-text-editor elementor-clearfix">
                                    <a href="#">Cloud SQL</a><br>
                                    <a href="#">Cloud Spanner</a>
                                </div>
                            </div>
                            <div class="d-inline-block iq-heading iq-image">
                                <img src="./assets/images/client/c1.png" class="img-fluid" alt="client-img">
                                <img src="./assets/images/client/c-a5.png" class="img-fluid" alt="client-img">
                            </div>
                        </li>
                        <li class="type-1 iq-rela">
                            <div class="d-inline-block iq-heading mt-2">
                                <p class="heading-title iq-title mb-0">NoSQL/Nonrelational</p>
                            </div>
                            <div class="d-inline-block iq-heading">
                                <div>
                                    Time Series<br>
                                    Streaming<br>
                                    Mobile <br>
                                    Web<br>
                                    IoT<br>
                                    Offline sync<br>
                                    Caching<br>
                                    Low Latency
                                </div>
                            </div>
                            <div class="d-inline-block iq-heading">
                                <div>
                                    <a href="#">Cloud Bigtable Cloud</a><br>
                                    <a href="#">FireStore Firebase Realtime</a><br>
                                    <a href="#">Database Cloud</a><br>
                                    <a href="#">Memorystore</a>
                                </div>
                            </div>
                            <div class="d-inline-block  iq-heading iq-image">
                                <img src="./assets/images/client/c4.png" class="img-fluid" alt="client-img">
                                <img src="./assets/images/client/c5.png" class="img-fluid" alt="client-img">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>