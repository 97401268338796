import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html'
})
export class BusinessComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center iq-title-box-2',
    title: 'Utilisé par les entreprises les plus inovantes    ',
    subTitle: 'Témoignage',
    titleIcon: ''
  };

  Image1: any[] = [
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/psg.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Chery.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/webank.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/esprit.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/medina-1.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/At-takafulia.png'},


  ];

  Image2: any[] = [
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/attijari-bank-tunisie.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/beautystore.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/miralina.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/iseg.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/arsii.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/ebtravel.png'},


  ];
  Image3: any[] = [
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Bountou1x2.png'},

    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/sejoursmedical-1.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/voyages2000.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/solusta.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/rassdtunisia.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/cnci.png'},

  ];
  Image4: any[] = [
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/tayarapro.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/labadira-hotel.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/jcc.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/promocote.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/PagesJaunes.png'},
    { image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/educanet.png'},


  ];

  List: any[] = [
    { name: 'Yannick Daniel', image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/02/round-img4-7.png' , design: 'PDG, PSG QATAR' , desc: 'Une véritable partenariat est né lors des différents échanges pour le développement et l’hébergement de notre boutique e-commerce dédié pour les parents les enfant et les Coachs du club paris saint germain.' },

    { name: 'Ameur Chayah', image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Ameur-chayah-miralina.png' , design: 'CEO, Miralina' , desc: 'On n’a rien d’autre que des expériences formidables avec Zenhosting. On a commencé de collaborer avec cette jeune start up depuis 3 ans et il a toujours été si facile d’obtenir de l’aide…Si impressionné par leur service et leurs connaissances. Service à la clientèle incroyable! Des prix équitables aussi! Nous continuerons à vous recommander à nos amis et clients' },
    { name: 'Kricha Khaled', image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/02/khaled-kricha.png' , design: 'Directeur Informatique, Ste La Paix' , desc: 'Zenhosting est la plateforme que je recommande vivement pour y avoir hébergé tous nos sites Web. Le service client est très disponible et attentifs aux sollicitations des clients.' },
    { name: 'Anis Ben Arbia', image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/02/anis-ben-arbia-educanet.png' , design: 'CEO, Educanet' , desc: 'Nous sommes très reconnaissants à zenhosting, pour ses services inégalés et surtout durant les périodes les plus difficiles. Zenhosting n’est pas seulement un fournisseur de services, mais aussi des conseillers qui vous aident à prendre la bonne décision et la meilleure stratégie.' },
    { name: 'Elyes ABDELADHIM', image: 'http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/ElyesABDELADHIM.png' , design: 'Responsable Infrastructure, Casual Bet' , desc: 'D’après mes expériences dans le domaine d’administration de l’Infrastructure Informatique, je trouve la plus part des services de Zenhosting à la hauteur par rapport au marché Tunisienne surtout au niveaux de support Technique qui est toujours disponible pendant 7/7 jours et 24/24 heures. Il donne l’opportunité à ces solutions pour l’adapter avec les besoins demandés par le client.' },
    
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
