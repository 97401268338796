<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START qloud7 REVOLUTION SLIDER 6.1.8 -->
<app-revolution-slider7></app-revolution-slider7>
<!-- End REVOLUTION SLIDER -->
<!-- Overview Block Start -->
<app-overview-block></app-overview-block>
<!-- Overview Block End -->
<!-- Icon Box Start -->
<app-icon-box></app-icon-box>
<!-- Icon Box End -->
<!-- Customer Story Start-->
<app-customer-story></app-customer-story>
<!-- Customer Story End-->
<!-- Process Start-->
<app-process></app-process>
<!-- Process End -->
<!-- Our Testimonial Start-->
<app-our-testimonial></app-our-testimonial>
<!-- Our Testimonial End-->
<!-- Blog start -->
<app-our-blog></app-our-blog>
<!-- Blog End -->
<!-- Footer start -->
<app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->