<section class="iq-client-section pt-0" style="background-image: linear-gradient(180deg, #F0FAFE 0%, #FFFFFF 100%);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 align-self-center" style="text-align: center;margin-top: 10px;"> 
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 align-self-center">
                <div class="iq-client iq-client-style-1   ">
                    <ul class="iq-client-col-6 iq-client-grid" >					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/webank.png" alt="client-img" >
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/attijari-bank-tunisie.png" alt="client-img" >
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/BH-Bank.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/psg.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/tayarapro.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Chery.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/beautystore.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/Bountou1x2.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/knoozfm.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/labadira-hotel.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/jcc.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/At-takafulia.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/medina-1.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/educanet.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/cnci.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/PagesJaunes.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/voyages2000.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/miralina.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/solusta.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/marayatours.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/rassdtunisia.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/sejoursmedical.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/promocote.png" alt="client-img">
					</li> 					<li>
						<img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/megapc.png" alt="client-img">
					</li> </ul>
                </div>
            </div>
           
        </div>
    </div>
</section>