import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./style.scss']

})
export class ProcessComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left iq-title-box-2',
    title: 'Applications pré-installées en quelque clics',
    subTitle: 'About us',
    titleIcon: '',
    description: 'Héberger son site soi-même devient un jeu d’enfant. Sélectionnée l’application que vous voulez installer parmi plus de 500 applications remplissez un formulaire et lancer l’installation le rest on s’on occupe.'

  };
  constructor() { }

  ngOnInit(): void {
  }

}
