<section style="background:transparent;background-image: radial-gradient(at center center, #F0FAFE 0%, #FFFFFF 100%)">
   
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/hebergement-web-tunisie-img-2.png" class="img-fluid" alt="qloud">
            </div>
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="javascript:void(0)">Commencez</a>
                </div>
            </div>
           
        </div>
    </div>
    
</section>
<section style="background:transparent;background-image: radial-gradient(at center center, #F0FAFE 0%, #FFFFFF 100%)">
   
    <div class="container">
        <div class="row">
           
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp2"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="javascript:void(0)">Commencez</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/speed-server-zenhosting-2048x1567.png" class="img-fluid" alt="qloud">
            </div>
           
        </div>
    </div>
    
</section>
<section style="background:transparent;background-image: radial-gradient(at center center, #F0FAFE 0%, #FFFFFF 100%)">
   
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/sec-2048x1567.png" class="img-fluid" alt="qloud">
            </div>
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp3"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="javascript:void(0)">Commencez</a>
                </div>
            </div>
           
        </div>
    </div>
    
</section>
<section style="background:transparent;background-image: radial-gradient(at center center, #F0FAFE 0%, #FFFFFF 100%)">
   
    <div class="container">
        <div class="row">
           
            <div class="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp4"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="javascript:void(0)">Commencez</a>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <img src="http://v4.zenhosting.pro/z/wp-content/uploads/2020/12/support-client-2048x1567.png" class="img-fluid" alt="qloud">
            </div>
           
        </div>
    </div>
    
</section>
