<p class="rs-p-wp-fix"></p>
<rs-module-wrap id="rev_slider_8_1_wrapper" data-alias="Home-7" data-source="gallery" style="background:transparent;padding:0;">
    <rs-module id="rev_slider_8_1" style="display:none;" data-version="6.1.8">
    <rs-slides>
        <rs-slide data-key="rs-11" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
            <img alt="banner-img" src="./assets/revslider/assets/transparent.png" data-bg="c:#ffffff;" data-parallax="off" class="rev-slidebg" data-no-retina>
        <!--
        -->
            <rs-layer
                id="slider-8-slide-11-layer-0"
                class="textgyreadventor"
                data-type="text"
                data-color="#021a48"
                data-rsp_ch="on"
                data-xy="x:l,l,c,c;xo:50px,36px,0,0;yo:230px,168px,127px,78px;"
                data-text="w:normal;s:70,61,46,50;l:90,70,53,62;a:left,left,center,center;"
                data-frame_0="y:100%;"
                data-frame_0_mask="u:t;"
                data-frame_1="st:310;sp:1200;sR:310;"
                data-frame_1_mask="u:t;"
                data-frame_999="o:0;st:w;sR:7490;"
                style="z-index:19;">Qloud for<span style =" color: #007bff"> Media</span> <br>and <span style =" color: #007bff"> Entertainment</span><br />Service
            </rs-layer>
            <!---->
            <rs-layer
                id="slider-8-slide-11-layer-1"
                data-type="shape"
                data-rsp_ch="on"
                data-xy="x:r;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:491px,359px,272px,481px;h:100%;"
                data-basealign="slide"
                data-frame_0="x:100%;"
                data-frame_0_mask="u:t;"
                data-frame_1="st:1410;sp:1000;sR:1410;"
                data-frame_1_mask="u:t;"
                data-frame_999="o:0;st:w;sR:6590;"
                style="z-index:8;background-color:rgba(0,123,252,0.1);"
            >
            </rs-layer>
            <!-- -->
            <rs-layer
                id="slider-8-slide-11-layer-2"
                data-type="shape"
                data-rsp_ch="on"
                data-xy="xo:51px,37px,160px,139px;y:b;yo:55px,40px,173px,32px;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:280px,204px,199px,195px;h:90px,65px,63px,69px;"
                data-border="bor:4px,4px,4px,4px;"
                data-frame_0="x:-100%;y:0,0,0px,0px;"
                data-frame_0_mask="u:t;x:0,0,0px,0px;y:0,0,0px,0px;"
                data-frame_1="x:0,0,0px,0px;y:0,0,0px,0px;st:1660;sp:1000;sR:1660;"
                data-frame_1_mask="u:t;x:0,0,0px,0px;y:0,0,0px,0px;"
                data-frame_999="o:0;st:w;sR:6340;"
                style="z-index:10;background-color:rgba(0,123,252,0.1);"
            >
            </rs-layer>
            <!-- -->
            <a
            id="slider-8-slide-11-layer-3"
            class="rs-layer"
            href="#" target="_self" rel="nofollow"
            data-type="button"
            data-bsh="c:rgba(0,122,255,0.2);v:7px,5px,4px,4px;b:13px,9px,7px,8px;s:0,0,0px,0px;"
            data-rsp_ch="on"
            data-xy="x:l,l,c,c;xo:60px,43px,-200px,-65px;y:b;yo:69px,50px,185px,42px;"
            data-text="w:normal;s:20,14,12,14;l:60,43,38,46;a:center;"
            data-dim="w:60px,43px,38px,46px;h:60px,43px,38px,46px;minw:60px,none,none,none;minh:60px,none,none,none;"
            data-border="bor:5px,5px,5px,5px;"
            data-frame_0="x:0,0,0px,0px;y:100%;"
            data-frame_0_mask="u:t;x:0,0,0px,0px;y:0,0,0px,0px;"
            data-frame_1="x:0,0,0px,0px;y:0,0,0px,0px;st:2140;sp:1200;sR:2140;"
            data-frame_1_mask="u:t;x:0,0,0px,0px;y:0,0,0px,0px;"
            data-frame_999="o:0;st:w;sR:5660;"
            data-frame_hover="c:#fff;bgc:#021a48;bor:5px,5px,5px,5px;sp:500ms;e:Power4.easeInOut;"
            style="z-index:11;background-color:#007bff;text-transform:uppercase;"
            ><i class="fa-play"></i>
            </a><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-4"
                class="textgyreadventor"
                data-type="text"
                data-color="#142149"
                data-rsp_ch="on"
                data-xy="x:l,l,c,c;xo:134px,98px,-135px,7px;y:t,t,b,b;yo:777px,678px,202px,64px;"
                data-text="w:normal;s:18,13,14,14;l:25,18,24,24;"
                data-frame_0="y:100%;"
                data-frame_0_mask="u:t;"
                data-frame_1="st:2680;sp:1200;sR:2680;"
                data-frame_1_mask="u:t;"
                data-frame_999="o:0;st:w;sR:5120;"
                style="z-index:12;">Best Videos
            </rs-layer><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-5"
                data-type="text"
                data-color="#5e7290"
                data-rsp_ch="on"
                data-xy="x:l,l,c,c;xo:135px,98px,-122px,19px;y:t,t,b,b;yo:803px,698px,185px,46px;"
                data-text="w:normal;s:14,10,12,12;l:25,18,22,22;"
                data-frame_0="y:100%;"
                data-frame_0_mask="u:t;"
                data-frame_1="st:2880;sp:1200;sR:2880;"
                data-frame_1_mask="u:t;"
                data-frame_999="o:0;st:w;sR:4920;"
                style="z-index:13;">Watch Our Video
            </rs-layer><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-6"
                data-type="image"
                data-rsp_ch="on"
                data-xy="x:r,r,c,c;xo:20px,14px,0,-5px;y:m;yo:-90px,-65px,-8px,10px;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:478px,349px,265px,194px;h:440px,321px,243px,179px;"
                data-frame_0="sX:0.9;sY:0.9;"
                data-frame_1="st:2180;sp:1000;sR:2180;"
                data-frame_999="o:0;st:w;sR:5820;"
                data-loop_0="x:-5px;"
                data-loop_999="x:5px;sp:3000;e:Sine.easeInOut;yym:t;"
                style="z-index:14;">
                <img src="./assets/revslider/assets/s-04.png" alt="img" width="478" height="440" data-no-retina>
            </rs-layer><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-7"
                class="rs-pxl-2"
                data-type="image"
                data-rsp_ch="on"
                data-xy="x:r,r,c,c;xo:111px,81px,0,-14px;y:m;yo:25px,18px,53px,64px;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:364px,266px,202px,124px;h:565px,413px,313px,193px;"
                data-frame_0="y:100%;"
                data-frame_0_mask="u:t;"
                data-frame_1="st:2520;sp:1200;sR:2520;"
                data-frame_1_mask="u:t;"
                data-frame_999="o:0;st:w;sR:5280;"
                style="z-index:15;"
            ><img src="./assets/revslider/assets/s-03.png" alt="img" width="364" height="565" data-no-retina>
            </rs-layer><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-8"
                class="rev-btn"
                data-type="button"
                data-rsp_ch="on"
                data-xy="x:l,l,c,c;xo:55px,40px,92px,0;y:b;yo:220px,250px,183px,129px;"
                data-text="w:normal;s:20,14,14,12;l:30,24,24,22;fw:500,500,500,400;"
                data-dim="minh:0px,none,none,none;"
                data-padding="t:12,10,10,10;r:40,30,30,30;b:12,10,10,10;l:40,30,30,30;"
                data-border="bor:10px,10px,10px,10px;"
                data-frame_0="y:100%;"
                data-frame_0_mask="u:t;"
                data-frame_1="st:730;sp:1200;sR:730;"
                data-frame_1_mask="u:t;"
                data-frame_999="o:0;st:w;sR:7070;"
                data-frame_hover="bgc:#021a48;bor:10px,10px,10px,10px;sp:500ms;e:Power4.easeInOut;bri:120%;"
                style="z-index:18;background-color:#007aff;"
                >Join Now
            </rs-layer><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-9"
                data-type="shape"
                data-rsp_ch="on"
                data-xy="x:c;xo:0,0,430px,310px;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:2px,1px,1px,1px;h:100%;"
                data-basealign="slide"
                data-frame_0="sX:0.9;sY:0.9;"
                data-frame_1="st:1730;sp:1000;sR:1730;"
                data-frame_999="o:0;st:w;sR:6270;"
                style="z-index:9;background-color:rgba(0,0,0,0.05);"
            >
            </rs-layer>
            <!-- -->
            <rs-layer
                id="slider-8-slide-11-layer-10"
                data-type="image"
                data-rsp_ch="on"
                data-xy="x:c;xo:3px,2px,1px,467px;y:b;yo:41px,29px,22px,103px;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:119px,87px,66px,40px;h:120px,87px,66px,40px;"
                data-frame_0="rX:-70deg;oZ:-50;"
                data-frame_1="oZ:-50;e:Power4.easeInOut;st:3360;sp:1750;sR:3360;"
                data-frame_999="o:0;st:w;sR:3890;"
                data-loop_999="rZ:360;sp:30000;"
                style="z-index:16;"
            >
            <img src="./assets/revslider/assets/slider-01.png" alt="img" width="131" height="132" data-no-retina>
            </rs-layer><!--
            -->
            <rs-layer
                id="slider-8-slide-11-layer-11"
                data-type="image"
                data-rsp_ch="on"
                data-xy="x:c;xo:2px,1px,0,348px;y:b;yo:52px,38px,28px,60px;"
                data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
                data-dim="w:98px,71px,53px,32px;h:99px,72px,54px,33px;"
                data-frame_0="sX:0.9;sY:0.9;"
                data-frame_1="st:3370;sp:1000;sR:3370;"
                data-frame_999="o:0;st:w;sR:4630;"
                style="z-index:17;"
                ><img src="./assets/revslider/assets/slider-02-1.png" alt="img" width="131" height="132" data-no-retina>
            </rs-layer><!--
            -->
        </rs-slide>
    </rs-slides>
    <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
    </rs-module>
    <script>
    if(typeof revslider_showDoubleJqueryError === "undefined") {
        function revslider_showDoubleJqueryError(sliderID) {
            var err = "<div class='rs_error_message_box'>";
            err += "<div class='rs_error_message_oops'>Oops...</div>";
            err += "<div class='rs_error_message_content'>";
                err += "You have some jquery.js library include that comes after the Slider Revolution files js inclusion.<br>";
                err += "To fix this, you can:<br>&nbsp;&nbsp;&nbsp; 1. Set 'Module General Options' -> 'Advanced' -> 'jQuery & OutPut Filters' -> 'Put JS to Body' to on";
                err += "<br>&nbsp;&nbsp;&nbsp; 2. Find the double jQuery.js inclusion and remove it";
            err += "</div>";
            err += "</div>";
            jQuery(sliderID).show().html(err);
        }
    }
    </script>
</rs-module-wrap>
