<div class="iq-client-compute overview-block-ptb">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="iq-client iq-client-style-1 text-center iq-has-grascale">
                    <app-client [Images]="Images"></app-client>
                </div>
            </div>
        </div>
    </div>
</div>

