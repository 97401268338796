import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./style.scss']

})
export class ProjectComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-left iq-title-box-2',
    title: 'Gérez votre empire de sites Web',
    subTitle: 'Espace client',
    titleIcon: '',
    description: 'Suivez l’usage de vos ressources, installer des application gérez vos hébergements , vos domaines , vos serveurs et vos projets digital en quelque clic depuis votre tableau de bord Zenhosting.'

  };
  constructor() { }

  ngOnInit(): void {
  }

}
