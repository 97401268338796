<section class="iq-getstarted">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp">
                <div class=" text-left iq-title-box iq-title-default iq-title-box-1">
                    <div class="iq-title-icon"></div>
                    <h4 class="iq-title">Learn and build </h4>
                    <p class="iq-title-desc">New to GCP? Get started with any GCP products for free with a $300 credit.</p>
                </div>
                <div class="iq-btn-container">
                    <a class="iq-button d-inline" [routerLink]="['/contact-us-1']">Try Free</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 mt-4 mt-lg-0">
                <div class=" text-left iq-title-box iq-title-default iq-title-box-1">
                    <div class="iq-title-icon"></div>
                    <h4 class="iq-title">Need more help?</h4>
                    <p class="iq-title-desc">Out Experts will help you build the right solution or find the right partner for your needs.</p>
                </div>
            
                <div class="iq-btn-container d-inline-block mr-4">
                    <a class="iq-button iq-btn-outline d-inline" [routerLink]="['/contact-us-1']"> Contact Sales </a>
                </div>
                
                <div class="iq-btn-container d-inline-block">
                    <a class="iq-button iq-btn-outline d-inline" [routerLink]="['/contact-us-1']"> Find a partner </a>
                </div>
            </div>
        </div>
    </div>
</section>