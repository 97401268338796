<section class="iq-pricing-section pt-0 wow fadeInUp" data-wow-duration="0.6s">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row" style="margin-bottom: 50px;">
            <div class="col-lg-1" style="margin-right: 100px;">
            </div>
            <div class="col-lg-3">

                <div style="text-align: center;">
                    <span
                        style="padding: 10%;border: rgb(0, 119, 255) 1px solid;border-radius: 8px;;color:rgb(0, 119, 255)"><i
                            class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;Annuel</span></div>

            </div>
            <div class="col-lg-3" style="margin-left: -120px;">

                <div style="text-align: center;">
                    <span
                        style="padding: 10%;border: rgb(0, 119, 255) 1px solid;border-radius: 8px;;color:rgb(0, 119, 255)"><i
                            class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;BI-Annuel
                            <span style="    background: #dc3545;
                            padding-left: 4px;
                            padding-right: 4px;
                            border-radius: 4px;
                            color: white;
                            font-size: 13px;
                            font-weight: 550;">-15%</span>
                        </span> </div>

            </div>
            <div class="col-lg-3" style="margin-left: -85px;">

                <div style="text-align: center;">
                    <span
                        style="padding: 10%;border: rgb(0, 119, 255) 1px solid;border-radius: 8px;color:rgb(0, 119, 255)"><i
                            class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i>&nbsp;TRI-Annuel
                            <span style="    background: #dc3545;
                            padding-left: 4px;
                            padding-right: 4px;
                            border-radius: 4px;
                            color: white;
                            font-size: 13px;
                            font-weight: 550;">-30%</span>
                        </span></div>

            </div>
            
        </div><br>
        <div class="row">
            <div *ngFor="let item of items" class="col-lg-3 col-md-6 col-sm-12">
                <div class="iq-price-container iq-price-table-6 text-center iq-box-shadow wow fadeInUp animate__animated animate__pulse {{ item.active !== undefined && item.active ? 'active' : '' }} "
                    style="border:2px  #037BFC solid;animation: pulse 0.7s ease infinite;
                    " [ngStyle]="{'animation': item.active==true ? 'pulse 0.7s ease infinite' : null }">
                    <div class="iq-price-header" style="border-top: 10px solid #fff;">
                        <span class="iq-price-label" style="font-size: 25px !important;">{{item.title}}</span>
                        <span style="color: grey;">{{item.soustitle}}</span>
                        <h4 class="iq-price"><span style='font-size:18px !important'>Dt&nbsp;</span>{{item.price}}<br
                                style="margin-top: -80px !important;"><span
                                style="font-size: 16px !important;" [innerHTML]="item.plan_desc"></span></h4>
                        <br>
                        <div class="iq-btn-container">
                            <a class="iq-button iq-btn-round d-inline" href="{{item.href}}">Commander &nbsp;<i
                                    class="tab-icon fa fa-shopping-cart" aria-hidden="true"></i></a>
                        </div>
                        <br>
                        <span>Or <span style="color:rgb(0, 119, 255)">Purchase now</span></span>
                    </div>
                    <div class="iq-price-body">
                        <div class="pricing-content-wrap content-desc style-1">
                            <hr style="width: 80%;margin-left: 10%;margin-bottom: 15px;margin-top: -25px;">
                            <div class="pricing-content">
                                <p style="text-align: left;"><i class="fa fa-globe"
                                        style="font-size: 19px;padding-left: 20px;" aria-hidden="true"></i> 3 Site web
                                </p>
                                <p style="text-align: left;"><i class="fa fa-hdd-o"
                                        style="font-size: 19px;padding-left: 20px;" aria-hidden="true"></i> 200 GB
                                    Espace <span style="text-decoration: underline;">SSD</span></p>
                                <p style="text-align: left;"><i class="fa fa-envelope"
                                        style="font-size: 19px;padding-left: 20px;" aria-hidden="true"></i> 20 mails PRO
                                </p>
                                <p style="text-align: left;"><i class="fa fa-database"
                                        style="font-size: 19px;padding-left: 20px;" aria-hidden="true"></i> 5 Bases de
                                    données</p>
                                <p style="text-align: left;padding-left: 20px;color: black;"><b>Inclus gratuitement
                                        :</b></p>
                                <p style="text-align: left;padding-left: 20px;"> Domaine .tn 1 an</p>
                                <p style="text-align: left;padding-left: 20px;"> Certificat SSL</p>
                                <p style="text-align: left;padding-left: 20px;"><span style="    background: #dc3545;
                                padding-left: 4px;
                                padding-right: 4px;
                                border-radius: 4px;
                                color: white;
                                font-size: 13px;
                                font-weight: 550;">New</span> CDN Cloudflare</p>
                                <p style="text-align: left;padding-left: 20px;"><span style="    background: #dc3545;
                                padding-left: 4px;
                                padding-right: 4px;
                                border-radius: 4px;
                                color: white;
                                font-size: 13px;
                                font-weight: 550;">New</span><span style="text-decoration: line-through;"> Site
                                        Builder</span></p>
                                <p style="text-align: left;padding-left: 20px;"><span style="    background: #dc3545;
                                padding-left: 4px;
                                padding-right: 4px;
                                border-radius: 4px;
                                color: white;
                                font-size: 13px;
                                font-weight: 550;">New</span><span style="text-decoration: line-through;"> Analyse
                                        SEO</span></p>
                            </div>
                            <div class="content-overlay-bg-color"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <br><br><br>
        <div class="theplus-accordion-wrapper elementor-accordion  " id="accordion5fd88a5d7cbcc"
            data-accordion-id="accordion5fd88a5d7cbcc" data-connection="" data-accordion-type="accordion"
            data-toogle-speed="300" role="tablist">
            <div class="theplus-accordion-item">
               
                <div id="elementor-tab-content-4041"
                    class="elementor-tab-content elementor-clearfix plus-accordion-content 0 active" data-tab="1"
                    role="tabpanel" aria-labelledby="elementor-tab-title-4041" style="display: block;border: #037BFC 2px solid;border-radius: 8px;">
                    <span style="width:100%;" (click)="verif=!verif">
                        <p style="text-align: center;margin-top: 25px;color:#037BFC "><i class="fas fa-plus"
                                style="font-size: 19px;" aria-hidden="true"></i> Voir tous les Fonctionnalités</p>
                    </span>
                    <div class="plus-content-editor" style="padding: 20px;transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);" *ngIf="verif==true" @ngIfAnimation>
                       
                        
                       
                        <div *ngIf="verif==true" @easeInOut id="tablepress-11_wrapper" class="dataTables_wrapper no-footer">
                            <table id="tablepress-11" style="border: 1px #fff solid;"
                                class="tablepress tablepress-id-11 tablepress-responsive tablepress-responsive-phone dataTable no-footer"
                                role="grid">
                                <thead style="background-color: #d9edf7;
                                font-weight: 700;
                                vertical-align: middle;">
                                    <tr class="row-1" role="row" style="border: 1px #fff solid;">
                                        <th class="column-1 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px #fff solid;text-align: left"><i class="fal fa-server" aria-hidden="true"></i><strong>
                                                Performance </strong></th>
                                        <th class="column-2 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px #fff solid;text-align: left"><i class="fal fa-cloud" aria-hidden="true"></i>
                                            Economique</th>
                                        <th class="column-3 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px #fff solid;text-align: left"><i class="fal fa-clouds" aria-hidden="true"></i> Zen
                                        </th>
                                        <th class="column-4 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px #fff solid;text-align: left"><i class="fab fa-cloudscale" aria-hidden="true"></i>
                                            Professionnel</th>
                                        <th class="column-5 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px #fff solid;text-align: left"><i class="fal fa-dumpster-fire" aria-hidden="true"></i>
                                            E-commerce</th>
                                    </tr>
                                </thead>
                                <tbody style="display: table-row-group;
                                vertical-align: middle;
                                border-color: inherit;border: 0px #fff solid;">






                                    <tr class="row-2" role="row" style="border: 1px #fff solid;">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-microchip" aria-hidden="true"></i> Limite
                                            CPU</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">1Ghz</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">2Ghz</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">2,5Ghz</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">3Ghz</td>
                                    </tr>
                                    <tr class="row-3" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-memory" aria-hidden="true"></i> Limite RAM
                                        </td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">700 MB</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">1000 MB</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">1300 MB</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">3000 MB</td>
                                    </tr>
                                    <tr class="row-4" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-cloud-upload" aria-hidden="true"></i>
                                            Espace disque </td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">100 GB</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">200 GB </td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">Illimités</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">Illimités</td>
                                    </tr>
                                    <tr class="row-5" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-network-wired" aria-hidden="true"></i>
                                            Bande passante</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">Illimités</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">Illimités</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">Illimités</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">Illimités</td>
                                    </tr>
                                    <tr class="row-6" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-disc-drive" aria-hidden="true"></i> Limite
                                            I/O</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">1MB/s</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">2MB/s</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">5MB/s</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">2MB/s</td>
                                    </tr>
                                    <tr class="row-7" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-tachometer-fast" aria-hidden="true"></i>
                                            Nombre d’unités de traitement</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">40</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">60</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">80</td>
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">125</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- #tablepress-11 from cache --><br>
                        <div *ngIf="verif==true" @easeInOut id="tablepress-10_wrapper" class="dataTables_wrapper no-footer">
                            <table id="tablepress-10"
                                class="tablepress tablepress-id-10 tablepress-responsive tablepress-responsive-phone dataTable no-footer"
                                role="grid" style="border: 0px #fff solid !important">
                                <thead style="background-color: #d9edf7;
                                font-weight: 700;
                                vertical-align: middle;border: 0px #fff solid !important;">
                                    <tr class="row-1" role="row" style="border: 0px white solid;">
                                        <th class="column-1 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px white solid;text-align: left;"><i class="far fa-user-lock"
                                                aria-hidden="true"></i><strong> OUTILS &amp; SÉCURITÉ</strong></th>
                                        <th class="column-2 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px white solid;text-align: left"><i class="fal fa-cloud" aria-hidden="true"></i>
                                            Economique</th>
                                        <th class="column-3 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px white solid;text-align: left"><i class="fal fa-clouds" aria-hidden="true"></i> Zen
                                        </th>
                                        <th class="column-4 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px white solid;text-align: left"><i class="fab fa-cloudscale" aria-hidden="true"></i>
                                            Professionnel</th>
                                        <th class="column-5 sorting_disabled" rowspan="1" colspan="1"
                                            style="width: 0px;border: 0px white solid !important;text-align: left"><i class="fal fa-dumpster-fire" aria-hidden="true"></i>
                                            E-commerce</th>
                                    </tr>
                                </thead>
                                <tbody>

















                                    <tr class="row-2" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-mail-bulk" aria-hidden="true"></i> Boites e-mail</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">10</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">20</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">100</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">illimitées</td>
                                    </tr><tr class="row-3" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-server" aria-hidden="true"></i> Taille max/Boite</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">2 GB</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">illimitées</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">illimitées</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">illimitées</td>
                                    </tr><tr class="row-4" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-mail" aria-hidden="true"></i> Webmails</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">Roundcube</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">Roundcube</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">Roundcube</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">Roundcube</td>
                                    </tr><tr class="row-5" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-pastafarianism" aria-hidden="true"></i> Antispam</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-6" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fab fa-microsoft" aria-hidden="true"></i> Outlook</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-7" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-file-search" aria-hidden="true"></i> Comptes FTP</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">10</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">20</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">illimitées</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">illimitées</td>
                                    </tr><tr class="row-8" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-globe-europe" aria-hidden="true"></i> Domaines supp</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2">1</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3">3</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4">5</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5">10</td>
                                    </tr><tr class="row-9" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-desktop" aria-hidden="true"></i> Directadmin &amp; Softaculous</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-10" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fab fa-linux" aria-hidden="true"></i> Cloudlinux OS</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-11" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fab fa-php" aria-hidden="true"></i> PHP5.X &amp; PHP7</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-12" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fab fa-php" aria-hidden="true"></i> PHP.INI editor </td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-13" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-virus" aria-hidden="true"></i> Scanner antivirus</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-14" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fal fa-chart-network" aria-hidden="true"></i>  Firewall</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-15" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="fab fa-expeditedssl" aria-hidden="true"></i> Comodo WAF</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-16" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-virus-slash" aria-hidden="true"></i> Antispam</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-17" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-fast-backward" aria-hidden="true"></i> Backup and Restore</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr><tr class="row-18" role="row">
                                        <td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-1"><i class="far fa-chart-pie" aria-hidden="true"></i> Statistiques</td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-2"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-3"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-4"><i class="fas fa-check" aria-hidden="true"></i></td><td style="text-align: left;border-left: 1px #fff solid;border-right: 1px #fff solid;" class="column-5"><i class="fas fa-check" aria-hidden="true"></i></td>
                                    </tr></tbody>
                            </table>
                        </div>
                        <!-- #tablepress-10 from cache -->
                       
                    </div>
                </div>

            </div>

        </div>
    </div>

</section>