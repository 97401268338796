<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- Breadcrumb Start -->
<app-top-banner [data]="data"></app-top-banner>
<!-- Breadcrumb End -->
<!-- Contact Card Start -->
<app-contact-card></app-contact-card>
<!-- Contact Card End -->
<!-- Contact us Start -->
<app-contact-us></app-contact-us>
<!-- Contact us End -->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->