<div *ngFor="let list of List" class="iq-masonry-item {{ list.class }}">
    <div class="iq-portfolio">
        <a href="javascript:void(0)" class="iq-portfolio-img">
            <img src="{{ list.image }}" class="img-fluid" alt="qloud-portfolio" />
            <div class="portfolio-link">
                <div class="icon"><i class="fa fa-link" aria-hidden="true"></i></div>
            </div>
        </a>
        <div class="iq-portfolio-content">
            <div class="details-box clearfix">
                <div class="consult-details">
                    <a [routerLink]="[list.link]">
                        <h5 class="link-color">{{ list.title }}</h5>
                        <p class="mb-0 iq-portfolio-desc">{{ list.desc }}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
