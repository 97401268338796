<section class="iq-pb-70">
    <img src="./assets/images/others/shape1.png" class="img-fluid shape-right" alt="QLOUD">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-sm-12 align-self-center mb-5 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-btn-container">
                    <a class="iq-button iq-btn-round has-icon btn-icon-right d-inline" [routerLink]="['/contact-us-1']">
                        Read More<i class="fa fa-file-o" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-7 col-sm-12">
                <div class="row">
                    <div *ngFor="let list of List1" class="col-lg-6 col-md-6">
                        <div class="iq-icon-box iq-icon-box-style-8  wow fadeInUp" data-wow-delay="0.2s">
                            <div class="icon-box-img">
                                <img src="{{ list.image }}" class="img-fluid" alt="qloud">
                            </div>
                            <div class="icon-box-content">
                                <h5 class="icon-box-title"><a href="#">{{ list.title }}</a></h5>
                                <p class="icon-box-desc">{{ list.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
 
                <div class="row">
                    <div *ngFor="let list of List2" class="col-lg-6 col-md-6">
                        <div class="iq-icon-box iq-icon-box-style-8  wow fadeInUp" data-wow-delay="0.6s">
                            <div class="icon-box-img">
                                <img src="{{ list.image }}" class="img-fluid" alt="qloud">
                            </div>
                            <div class="icon-box-content">
                                <h5 class="icon-box-title"><a href="#">{{ list.title }}</a></h5>
                                <p class="icon-box-desc">{{ list.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </section>