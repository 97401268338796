<section class="iq-counter-section pt-0 iq-pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mb-lg-0 mb-5">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div class="iq-list iq-two-column">
                    <ul class="iq-list-with-icon">
                        <li><i class="ion ion-checkmark-round"></i>Boost SEO ranking</li>
                        <li><i class="ion ion-checkmark-round"></i> Social Sharing</li>
                        <li><i class="ion ion-checkmark-round"></i>Marketing</li>
                        <li><i class="ion ion-checkmark-round"></i>Retention</li>
                        <li><i class="ion ion-checkmark-round"></i> Visual Reviews </li>
                        <li><i class="ion ion-checkmark-round"></i> Reviews Generation</li>
                    </ul>
                </div>
                <div class="iq-btn-container mt-4">
                    <a class="iq-button iq-btn-medium iq-btn-round iq-btn-flat d-inline" href="javascript:void(0)">Read More</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="iq-counter text-center iq-box-shadow iq-counter-style-1">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                    <span class="timer" data-to="50" data-speed="5000">50</span>
                                    <span class="counter-after-content"></span>
                                    <span class="counter-symbol">+</span></p>
                                <h5 class="counter-title-text">Engineers and  Developers</h5>
                            </div>
                        </div>
                        <div class="iq-counter text-center iq-box-shadow iq-counter-style-1">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                    <span class="timer" data-to="100" data-speed="5000">100</span>
                                    <span class="counter-after-content">k</span>
                                    <span class="counter-symbol">+</span></p>
                                <h5 class="counter-title-text">Customers</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="iq-counter text-center iq-box-shadow iq-counter-style-1">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                    <span class="timer" data-to="35" data-speed="5000">35</span>
                                    <span class="counter-after-content">m</span>
                                    <span class="counter-symbol">+</span></p>
                                <h5 class="counter-title-text">Instances Deployed</h5>
                            </div>
                        </div>
                        <div class="iq-counter text-center iq-box-shadow iq-counter-style-1">
                            <div class="counter-content">
                                <p class="iq-counter-info">
                                    <span class="timer" data-to="15" data-speed="5000">15</span>
                                    <span class="counter-after-content">m</span>
                                    <span class="counter-symbol">+</span></p>
                                <h5 class="counter-title-text">Support answers  per month</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>