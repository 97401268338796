<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START REVOLUTION SLIDER -->
<app-revolution-slider1></app-revolution-slider1>
<!-- END REVOLUTION SLIDER -->
<!-- Features Start -->
<!-- Features End -->
<!-- Counter Start -->
<!-- Counter End -->
<!-- Service Tab Start -->
<!-- Service Tab End -->
<!-- Pricing Start -->
<app-pricing-plan></app-pricing-plan>
<app-project></app-project>
    <!-- Pricing End -->
    <app-features></app-features>
    <app-about-us></app-about-us>
    <app-business></app-business>
    <app-product></app-product>
<app-questions></app-questions>
    <app-get-started></app-get-started>

<!-- Icon-box Start -->
<!-- Icon-box End -->
<!-- Your Application Start -->
<!-- Your Application End -->
<!-- Testimonial Start -->
<!-- Testimonial End -->
<!-- Blog Start -->
<!-- Blog End -->
<!-- Footer start -->
<app-footer [logoImg]="logoImage" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->