<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 align-self-center">
                <div class="iq-client iq-client-style-1  iq-has-grascale">
                    <app-client [Images]="Images1"></app-client>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-12 col-md-12 col-sm-12 align-self-center">
                <div class="iq-client iq-client-style-1  iq-has-grascale">
                    <app-client [Images]="Images2"></app-client>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-12 col-md-12 col-sm-12 align-self-center">
                <div class="iq-client iq-client-style-1  iq-has-grascale">
                    <app-client [Images]="Images3"></app-client>
                </div>
            </div>
        </div>
    </div>
</section>