import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./style.scss']

})
export class AboutUsComponent implements OnInit {

  titleSectionProp: any = {
    class: 'iq-title-box-2',
    title: 'Applications pré-installées en quelque clics',
    subTitle: 'One-Click Install',
    titleIcon: '',
    description: 'Si vous débutez dans l’hébergement Web, la création d’un site Web à partir de zéro peut sembler être une tâche fastidieuse. Choisissez parmi une variété d’applications pré-assemblées et laissez notre installateur astucieux en 1 clic faire le travail pour vous!'
  };

  titleSectionProp2: any = {
    class: 'iq-title-box-2',
    title: 'Serveurs Ultra-rapides    ',
    subTitle: 'Performance',
    titleIcon: '',
    description: 'Tous nos serveurs sont localisés en Tunisie, Amérique du nord et en Françe. Votre site bénéficie donc d’une vitesse d’affichage exceptionnelle partout dans le monde. deux facteurs importants pour un référencement efficace et une haute disponibilité garantie.'
  };
  titleSectionProp3: any = {
    class: 'iq-title-box-2',
    title: 'Sécurité en temps réél    ',
    subTitle: 'Sécurité',
    titleIcon: '',
    description: 'Nos comptes d’hébergement sont sécurisés via des outils basé sur l’intelligence artificielle , un WAF qui met à l’abri votre site web de toute tentative de piratage via les Injections, un antivirus qui scan chaque fichier uploader sur votre serveurs est vérifié immédiatement et en temps réel les menaces, une Protection Anti-DDOS qui permet d’aspirer tous les attaques DDOS et un firewall qui permet de bloquer tous les tentatives de brute force.'
  };
  titleSectionProp4: any = {
    class: 'iq-title-box-2',
    title: 'Assistance Professionnelle    ',
    subTitle: 'Support hébergement web    ',
    titleIcon: '',
    description: 'Nous savons qu’il y a une courbe d’apprentissage lors de la configuration d’un site Web, surtout si c’est la première fois que vous créez un site. C’est pourquoi nous avons tout mis en œuvre pour nous assurer que nous sommes là pour vous lorsque vous en avez besoin. Que vous ayez une simple question sur votre compte d’hébergement ou que vous ayez besoin d’une assistance supplémentaire pour l’enregistrement d’un domaine, nous pouvons vous aider.'
  };


  supports: any[] = [
    { title: 'Boost SEO ranking' },
    { title: 'Social Sharing' },
    { title: 'Marketing' },
    { title: 'Retention' },
    { title: 'Visual Reviews' },
    { title: 'Reviews Generation' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
