<div class="iq-hosting-txt overview-block-pb wow fadeInUp">
    <div class="container">
        <div class="row iq-hosting-list">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 {{  i < List.length - 1  ? 'border-right mb-lg-0 mb-4' : '' }} {{  i != 0 ? 'pl-lg-5' : '' }}">
                <h4 class="heading-title mb-4 ">{{ list.title }}</h4>
                <div class="iq-list  iq-one-column">
                    <ul class="iq-list-with-icon">
                        <li *ngFor="let service of list.services" ><i class="ion ion-checkmark-round"></i>{{ service.title }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>