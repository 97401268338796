<!-- Header component start-->
<app-header [logoImg]="logoImage" [classHeader]="classHeader" [navItemList]="navItems"></app-header>
<!-- Header component end-->
<!-- START qloud4 REVOLUTION SLIDER 6.1.8 -->
<app-revolution-slider4></app-revolution-slider4>
<!-- End REVOLUTION SLIDER -->
<!-- Hosting Listing Start -->
<app-hosting-list></app-hosting-list>
<!-- Hosting Listing End-->
<!-- Process Start -->
<app-process></app-process>
<!-- Process End-->
<!-- Help start -->
<app-help></app-help>
<!-- Help End-->
<!-- Service Tab Start -->
<app-service-tab></app-service-tab>
<!-- Service Tab End -->
<!-- Pricing plan Start-->
<app-pricing-plan></app-pricing-plan>
<!-- Pricing plan End-->
<!-- Blog Start -->
<app-our-blog></app-our-blog>
<!-- Blog End -->
<!-- Our Partners Start-->
<app-our-partner></app-our-partner>
<!-- Our Partners End-->
<!-- Footer start -->
<app-footer [logoImg]="footerlogoImg" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->