<div class="wow fadeInUp overview-block-pt mb-5" data-wow-duration="0.6s">
    <div class="container">
       <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 border-right mb-4 mb-lg-0">
                <h4>Solving for data-driven<br> media at scale</h4>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 ">
                <p class="mb-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.</p>
            </div>
       </div>
    </div>
 </div>