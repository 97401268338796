<div class=" overview-block-ptb">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <img src="./assets/images/others/01.png" class="img-fluid" alt="contact" />
            </div>
            <div class="col-lg-6 col-md-12">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
                <div role="form" class="wpcf7">
                    <form class="wpcf7-form" >
                        <div class=row>
                            <div class="col-lg-6">
                                <span class="wpcf7-form-control-wrap first-name">
                                    <input type="text" name="first-name" value="" size="40"  class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"  placeholder="First Name" />
                                </span>
                            </div>
                            <div class="col-lg-6">
                                <span class="wpcf7-form-control-wrap last-name">
                                    <input type="text" name="last-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"  placeholder="Last Name" />
                                </span>
                            </div>
                            <div class="col-lg-6">
                                <span class="wpcf7-form-control-wrap your-email">
                                    <input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"  placeholder="Email Address" />
                                </span>
                            </div>
                            <div class="col-lg-6">
                                <span class="wpcf7-form-control-wrap tel-554">
                                    <input type="tel" name="tel-554" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel" placeholder="Phone" />
                                </span>
                            </div>
                            <div class="col-lg-12">
                                <p> 
                                    <span class="wpcf7-form-control-wrap your-message">
                                        <textarea name="your-message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea" placeholder="Message"></textarea>
                                    </span>
                                </p>
                            </div>
                            <div class="col-lg-12">
                                <input type="submit" value="Send" class="wpcf7-form-control wpcf7-submit" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
