<section class="pb-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 wow fadeInUp">
                <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
            </div>
        </div>
        <div class="row iq-compute-list wow fadeInUp">
            <div *ngFor="let list of List;let i = index" class="col-lg-4 col-md-6 {{  i < List.length - 1  ? 'border-right mb-lg-0 mb-4' : '' }} {{  i != 0 ? 'pl-lg-5' : '' }}">
                <h4 class="heading-title mb-4 ">{{ list.title }}</h4>
                <p *ngIf="list.description">{{ list.description }}</p>
                <div *ngIf="list.services" class="iq-list iq-one-column">
                    <ul class="iq-list-with-icon">
                        <li *ngFor="let service of list.services" ><i class="ion ion-checkmark-round"></i>{{ service.title }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
